import React from "react";
import { PricingData } from "../Data/PricingData";
import { Link } from "react-router-dom";
import { HiOutlineArrowSmRight } from "react-icons/hi";

const PricingCards = () => {
  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 gap-y-8 relative z-[1]">
          {PricingData.map((e, i) => (
            <div key={i} className="p-7 flex flex-col items-center gap-3 bg-troo-lightpink hover:bg-troo-white relative before:w-full group before:h-full before:content-[''] before:top-0 before:rounded before:left-0 before:bg-troo-primary hover:before:top-2 hover:before:left-2  before:absolute before:z-[-1] z-auto before:transition-all before:ease-in-out before:duration-500">
              <h3>{e.name}</h3>
              <p className="text-center">{e.para}</p>
              <div>
                <div className="flex items-end relative pl-5">
                  <h2>{e.price}</h2>
                  <p> /Month </p>
                  <h6 className="absolute left-0 top-0 font-bold">$</h6>
                </div>
              </div>
              <Link className="btn1 mb-5 bg-troo-primary group-hover:bg-troo-secondary text-troo-white group transition-all ease-in-out duration-500">
                Send a Message
                <HiOutlineArrowSmRight className="w-8 h-8 p-1 flex justify-center items-center bg-troo-secondary group-hover:bg-troo-primary transition-all ease-in-out duration-500  rounded-full" />
              </Link>
              <div className="flex w-full items-center py-5 flex-col gap-3 group-hover:bg-troo-secondary group-hover:text-troo-white transition-all ease-in-out duration-500">
                {e.feture.map((ele,ind)=>
                <p key={ind} className="flex items-center gap-2">{ele.data}</p>
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default PricingCards;
