import React from "react";
import { BrandData } from "../Data/BrandData";
import { motion } from "framer-motion";

const BrandLogos = () => {
  return (
        <div className=" lg:left-1/2 lg:transform lg:-translate-x-1/2 w-[90%] max-w-[1400px] mx-auto top-[-150px]  lg:absolute rounded shadow bg-troo-white p-10">
          <div className="text-center mb-10">
            <p>We are very fortunate to work with these amazing partners</p>
          </div>
          <div className="flex flex-col sm:flex-row justify-center sm:justify-between gap-5 mb-5">
            {BrandData.map((e, i) => (
              <div className="bg-troo-white  w-full " key={i}>
                <img className="w-60 mx-auto" src={e.img} alt={e.img} />
              </div>
            ))}
          </div>
          <div className="2xl:flex w-full xl:justify-center">
            <div className="flex flex-col sm:flex-row justify-center  sm:justify-between gap-5  w-full  xl:w-[84%]">
              {BrandData.slice(0, 5).map((e, i) => (
                <div className="bg-troo-white  w-full " key={i}>
                  <img className="w-60 mx-auto" src={e.img} alt={e.img} />
                </div>
              ))}
            </div>
          </div>
        </div>

  );
};

export default BrandLogos;
