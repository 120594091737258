import React, { useState } from "react";
import { Link, createSearchParams } from "react-router-dom";
import { ProjectData } from "../Data/ProjectData";
import { GoArrowUpRight } from "react-icons/go";
import { motion } from "framer-motion";

const Projects = ({ isHomePage }) => {
  const [filterData, setFilterData] = useState(ProjectData);
  const [activeTab, setActiveTab] = useState("all");

  const handleFilterList = (field) => {
    setActiveTab(field);
    if (field === "all") {
      setFilterData(ProjectData);
    } else {
      setFilterData(ProjectData.filter((data) => data.sub === field));
    }
  };

  return (
    <div className={` py-12 lg:py-24 ${isHomePage ? "bg-troo-lightpink" : ""}`}>
      <div className="main_container1">
        {isHomePage && (
          <div className="flex flex-col gap-5 mb-12">
            <motion.h2 initial={{ x: -100 }}
            whileInView={{ x: 1 }}
            transition={{ duration: 1, type: "spring", stiffness: 100 }} className="max-w-[550px] text-center mx-auto">
              Let’s check our latest project we have recently done
            </motion.h2>
            <motion.p initial={{ x: 100 }}
            whileInView={{ x: 1 }}
            transition={{ duration: 1, type: "spring", stiffness: 100 }} className="max-w-[750px] text-center mx-auto">
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour.
            </motion.p>
          </div>
        )}
        <div>
          {isHomePage && (
            <motion.div initial={{ y: -100 }}
            whileInView={{ y: 1 }}
            transition={{ duration: 1, type: "spring", stiffness: 100 }} className="text-center">
              <div
                className="lg:mb-16 mb-8  text-center inline-flex flex-col lg:flex-row gap-5 py-5 px-5 rounded-3xl lg:rounded-[100px] bg-troo-secondary text-troo-white"
              >
                {[
                  "all",
                  "E-commerce Design",
                  "Web Development",
                  "Templates Design",
                ].map((tab) => (
                  <p
                    key={tab}
                    className={`px-5 py-3 rounded-[100px] border inline-flex justify-center border-troo-white cursor-pointer ${
                      activeTab === tab ? "bg-troo-primary" : ""
                    }`}
                    onClick={() => handleFilterList(tab)}
                  >
                    {tab === "all" ? "All Works" : tab}
                  </p>
                ))}
              </div>
            </motion.div>
          )}
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {filterData.map((project, i) => (
              <Link
              to={`/our-projects/projects-details?${createSearchParams({id : project.id})}`}
                key={i}
                className="relative before:content-[''] before:absolute before:w-full before:h-full before:bg-troo-secondary before:top-0 before:left-0 before:opacity-0 hover:before:opacity-50 before:transition-all before:ease-in-out before:duration-500 group overflow-hidden "
              >
                <div>
                  <img
                    className="w-full"
                    src={project.img}
                    alt={project.name}
                  />
                </div>
                <div className="absolute bg-troo-secondary w-full bottom-0 left-0  ">
                  <div className="text-troo-white flex justify-between">
                    <div className="flex flex-col gap-3 p-5">
                      <p>{project.sub}</p>
                      <h3>{project.name}</h3>
                    </div>
                    <div className="w-20 h-full absolute right-[-100px] group-hover:right-0 flex justify-center items-center bg-troo-white text-troo-secondary transition-all ease-in-out duration-500">
                      <GoArrowUpRight className="text-4xl" />
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
          {isHomePage && (
            <div className="text-center flex justify-center mt-10">
              <Link
              to="/our-projects"
                className="w-[100px] h-[100px] flex justify-center items-center bg-troo-primary rounded-full border-[7px] outline-dashed outline-1 outline-troo-secondary text-troo-white
          border-troo-white transition-all ease-in-out duration-500"
              >
                View All Projects
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Projects;
