export const HeaderData = [
  {
    title: "Home",
    path: "/",
  },
  {
    title: "about us",
    path: "/about-us",
    heading: "About Us",
  },
  {
    title: "our services",
    path: "#",
    subNav: [
      {
        subTitle: "our services",
        heading: "What We Do",
        path: "/our-services",
      },
      {
        subTitle: "services details",
        heading: "UX/UI Design Service",
        path: "/our-services/services-details",
      },
    ],
  },
  {
    title: "Pages",
    path: "#",
    subNav: [
      {
        subTitle: "our projects",
        heading: "Recently Done Projects",
        path: "/our-projects",
      },
      {
        subTitle: "projects details",
        heading: "Online Grocery Store",
        path: "/our-projects/projects-details",
      },
      {
        subTitle: "our team",
        heading: "Meet Awesome Team Member",
        path: "/our-team",
      },
      {
        subTitle: "our team details",
        heading: "About Paige Turner",
        path: "/our-team/our-team-details",
      },
      
      {
        subTitle: "testimonial",
        heading: "2M+ Satisfied Customers",
        path: "/testimonial",
      },
      {
        subTitle: "pricing plan",
        heading: "We Have Plan Small to Large",
        path: "/pricing-plan",
      },
   
      {
        subTitle: "faq",
        heading: "Popular Questions ",
        path: "/faq",
      },
      {
        subTitle: "error 404",
        path: "/error-404",
      },
      {
        subTitle: "coming soon",
        path: "/coming-soon",
      },
    ],
  },
  {
    title: " Our Blogs",
    path: "#",
    subNav: [
      {
        subTitle: "our blogs",
        heading: "Our News and Blogs",
        path: "/our-blogs",
      },
      {
        subTitle: "blog details",
        heading: "Our News and Blogs",
        path: "/our-blogs/blog-details",
      },
    ],
  },
  {
    title: "contact us",
    path: "/contact-us",
    heading: "Get in Touch With Us",
  },
  {
    title: "carrier",
    path: "/carrier",
    heading: "Featured Job Offers",
  },
  {
    title: "get a quote",
    path: "/get-a-quote",
    heading: "Get in Touch With Us",
  },

];
