import React from "react";
import { AboutData } from "../Data/AboutData";
import { Link } from "react-router-dom";
import { HiMiniArrowRight } from "react-icons/hi2";
import BrandLogos from "./BrandLogos";
import { motion } from "framer-motion";

const Abouts = ({ isHomePage }) => {
  return (
    <div
      className={`bg-troo-lightpink ${
        isHomePage
          ? "pt-12 relative lg:pt-72 pb-12 lg:pb-24 "
          : "py-12 lg:py-24"
      }`}
    >
      {isHomePage && <BrandLogos />}
      <div className="main_container1">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 gap-y-6 mb-10">
          <motion.div
            initial={{ x: -100 }}
            whileInView={{ x: 1 }}
            transition={{ duration: 1, type: "spring", stiffness: 100 }}
            className="mt-10 lg:mt-0"
          >
            <h2 className="lg:max-w-[500px]">
              Award-winning web design agency based in NewYork & Atlanta, USA
            </h2>
          </motion.div>
          <motion.div
            initial={{ x: 100 }}
            whileInView={{ x: 1 }}
            transition={{ duration: 1, type: "spring", stiffness: 60 }}
            className="flex justify-end "
          >
            <p className=" lg:max-w-[550px] pl-10 relative before:content-[''] before:absolute before:w-24 before:h-24 before:left-0 before:top-3 before:bg-troo-primary before:opacity-10 before:rounded-full after:content-[''] after:absolute after:w-24 after:h-24 after:border after:border-troo-secondary after:border-opacity-20 after:rounded-full after:left-[-15px] after:top-[-30px]">
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable.
            </p>
          </motion.div>
        </div>
        <motion.div
         initial={{ y: -100 }}
         whileInView={{ y: 0 }}
         transition={{ duration: 1, type: "spring", stiffness: 60 }}
         className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-7 relative z-[1] ">
          {AboutData.map((e, i) => (
            <Link
              key={i}
              className="bg-troo-white px-5 py-8 flex flex-col gap-3 relative before:w-full group before:h-full before:content-[''] before:rounded before:top-0 before:left-0 before:bg-troo-primary hover:before:top-2 hover:before:left-2  before:absolute before:z-[-1] z-auto before:transition-all before:ease-in-out before:duration-500"
            >
              <div className="rounded-full group-hover:bg-opacity-100 transition-all ease-in-out duration-500  bg-troo-primary bg-opacity-10 w-20 h-20 flex justify-center items-center">
                <img src={e.img} alt={e.img} />
              </div>
              <h3>{e.name}</h3>
              <p>{e.para}</p>
              <div className="flex items-center gap-3">
                <p className="font-bold">Read More</p>
                <HiMiniArrowRight />
              </div>
            </Link>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default Abouts;
