import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import Carrier from '../../Components/Carrier'
import useDocumentTitle from '../../Hooks/PageTitle'

const CarrierPage = () => {
  useDocumentTitle("TRoo Web Design Agency | Carrier Page")
  return (
    <Layout>
        <BannerGlobal/>
        <Carrier/>
    </Layout>
  )
}

export default CarrierPage