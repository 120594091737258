import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import BlogDetails from '../../Components/BlogDetails'
import useDocumentTitle from '../../Hooks/PageTitle'

const BlogDetailsPage = () => {
  useDocumentTitle("TRoo Web Design Agency | Blogs Details Page")
  return (
   <Layout>
    <BannerGlobal/>
    <BlogDetails/>
   </Layout>
  )
}

export default BlogDetailsPage