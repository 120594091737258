export const ServiceData = [
  {
    id: 1,
    name: "Business Strategy",
    para: "There are many variations of passages of Lorem Ipsum available.",
  },
  {
    id: 2,
    name: "Product Design",
    para: "There are many variations of passages of Lorem Ipsum available.",
  },
  {
    id: 3,
    name: "Web Design",
    para: "There are many variations of passages of Lorem Ipsum available.",
  },
  {
    id: 4,
    name: "App Design",
    para: "There are many variations of passages of Lorem Ipsum available.",
  },
  {
    id: 5,
    name: "Templates",
    para: "There are many variations of passages of Lorem Ipsum available.",
  },
  {
    id: 6,
    name: "UX/UI Design",
    para: "There are many variations of passages of Lorem Ipsum available.",
  },
  {
    id: 7,
    name: "Website Design",
    para: "There are many variations of passages of Lorem Ipsum available.",
  },
  {
    id: 8,
    name: "Web Development",
    para: "There are many variations of passages of Lorem Ipsum available.",
  },
  {
    id: 9,
    name: "E-Commerce Website",
    para: "There are many variations of passages of Lorem Ipsum available.",
  },

  {
    id: 10,
    name: " Support & Maintenance",
    para: "There are many variations of passages of Lorem Ipsum available.",
  },
  {
    id: 11,
    name: "WordPress Support",
    para: "There are many variations of passages of Lorem Ipsum available.",
  },
  {
    id: 12,
    name: "Hosting",
    para: "There are many variations of passages of Lorem Ipsum available.",
  },
  {
    id: 13,
    name: "Mobiel Apps Design",
    para: "There are many variations of passages of Lorem Ipsum available.",
  },

  {
    id: 14,
    name: "Graphic Design",
    para: "There are many variations of passages of Lorem Ipsum available.",
  },
  {
    id: 15,
    name: "Responsive Design",
    para: "There are many variations of passages of Lorem Ipsum available.",
  },
  {
    id: 16,
    name: "CMS Implementation",
    para: "There are many variations of passages of Lorem Ipsum available.",
  },
  {
    id: 17,
    name: "Wireframing",
    para: "There are many variations of passages of Lorem Ipsum available.",
  },
  {
    id: 18,
    name: "Email Template",
    para: "There are many variations of passages of Lorem Ipsum available.",
  },
  {
    id: 19,
    name: "UX Strategy",
    para: "There are many variations of passages of Lorem Ipsum available.",
  },
  {
    id: 20,
    name: "Video production",
    para: "There are many variations of passages of Lorem Ipsum available.",
  },
];
