import React from "react";
import { CarrierData } from "../Data/CarrierData";
import { Link, createSearchParams } from "react-router-dom";
import { TbFlag3 } from "react-icons/tb";
import { GrLocation } from "react-icons/gr";
import { FiUser } from "react-icons/fi";
import { CiCalendarDate } from "react-icons/ci";
import { HiOutlineArrowSmRight } from "react-icons/hi";

const Carrier = () => {
  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 relative z-[1]">
          {CarrierData.map((e, i) => (
            <Link
            to={`/carrier/job-details?${createSearchParams({id : e.id})}`}
              key={i}
              className="bg-troo-lightpink hover:bg-troo-white p-5 relative before:w-full group before:h-full before:content-[''] before:top-0 before:left-0 before:bg-troo-primary hover:before:top-1 hover:before:left-1 before:rounded before:absolute before:z-[-1] z-auto before:transition-all before:ease-in-out before:duration-500"
            >
              <div className="flex  items-center gap-3 sm:gap-8">
                <div className="flex flex-col p-3 rounded bg-troo-secondary group-hover:bg-troo-primary justify-center items-center text-troo-white transition-all ease-in-out duration-500">
                  <p>Opening</p>
                  <p className="sm:text-4xl text-xl font-viga">{e.openingNum}</p>
                </div>
                <div>
                  <h3>{e.name}</h3>
                  <p>{e.experiance}</p>
                </div>
              </div>
              <div className="flex flex-col gap-4 py-8">
                <p className="flex items-center gap-3">
                  <TbFlag3 />
                  <span className="font-bold">Job type:</span>
                  {e.job_type}
                </p>
                <p className="flex items-center gap-3">
                  <GrLocation />
                  <span className="font-bold">Location:</span>
                  {e.location}
                </p>
                <p className="flex items-center gap-3">
                  <FiUser />
                  <span className="font-bold">Vacancy:</span>
                  {e.vacancy}
                </p>
                <p className="flex items-center gap-3">
                  <CiCalendarDate />
                  <span className="font-bold">Date:</span>
                  {e.date}
                </p>
              </div>
              <div className="absolute scale-0 group-hover:scale-100 transition-all ease-in-out duration-500 left-6 bottom-[-20px] bg-troo-primary rounded-full text-troo-white p-2">
                <HiOutlineArrowSmRight className="text-2xl" />
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Carrier;
