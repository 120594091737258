import React from "react";
import { CaseStudyData } from "../Data/CaseStudyData";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const CaseStudy = () => {
  return (
    <div className="bg-caseStudy-bg bg-no-repeat w-full py-12 lg:py-24">
      <div className="main_container1">
        <motion.div initial={{ y: -100 }}
            whileInView={{ y: 1 }}
            transition={{ duration: 1, type: "spring", stiffness: 100 }} className="text-center mb-10">
          <p>Working Process</p>
          <h2>How does we works</h2>
        </motion.div>
        <motion.div initial={{ y: 100 }}
            whileInView={{ y: 1 }}
            transition={{ duration: 1, type: "spring", stiffness: 100 }} className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-8">
          {CaseStudyData.map((e, i) => (
            <Link className="p-5 flex flex-col items-center gap-5 group">
              <div
                className={`p-5 flex flex-col items-center gap-5 group arror_Img ${
                  i === CaseStudyData.length - 1
                    ? ""
                    : 'relative after:content-[""] after:absolute after:bg-arrow after:bg-no-repeat after:top-[50%] after:left-[50%] after:w-36 after:h-4 after:transform after:translate-x-[70%] after:-translate-y-1/2'
                }`}
              >
                <div className="w-24 h-24 flex justify-center items-center bg-troo-whitefade rounded-full border-[7px] group-hover:outline-dashed outline-1 outline-troo-secondary group-hover:bg-troo-secondary  border-troo-white transition-all ease-in-out duration-500">
                  <img className="rounded" src={e.img} alt={e.name} />
                </div>
              </div>
              <h3 className="text-center">{e.name}</h3>
              <p className="text-center">{e.para}</p>
            </Link>
          ))}
        </motion.div>
      </div>
    </div>
  );
};

export default CaseStudy;
