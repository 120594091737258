import React from "react";
import { Link } from "react-router-dom";
import { createSearchParams } from "react-router-dom";
import { ServiceData } from "../Data/ServiceData";
import { HiOutlineArrowSmRight } from "react-icons/hi";
import { GoArrowUpRight } from "react-icons/go";
import serviceImg from "../images/serviceImg.png";
import { motion } from "framer-motion";

const Service = () => {
  return (
    <div className="bg-troo-secondary bg-service-bg bg-no-repeat bg-cover w-full py-12 lg:py-24">
      <div className="main_container1">
        <div className="flex flex-col lg:flex-row  justify-between gap-12 text-troo-white">
          <motion.div initial={{ x: -100 }}
            whileInView={{ x: 1 }}
            transition={{ duration: 1, type: "spring", stiffness: 100 }} className="lg:max-w-[35%] flex flex-col gap-8 z-[1]">
            <h2>What can we do for you? our services</h2>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour.
            </p>
            <div>
              <Link className="btn1 bg-troo-primary hover:bg-troo-white text-troo-white hover:text-troo-secondary group transition-all ease-in-out duration-500">
                Contact Us Now
                <HiOutlineArrowSmRight className="w-8 h-8 p-1 flex justify-center items-center bg-troo-secondary group-hover:bg-troo-primary transition-all ease-in-out duration-500  rounded-full" />
              </Link>
            </div>
            <div className="mt-10 relative  before:content-[''] before:absolute before:w-full before:h-full before:bg-troo-primary before:rounded before:top-2 before:left-2 z-auto before:z-[-1]">
              <img className="w-full " src={serviceImg} alt="serviceImg" />
            </div>
          </motion.div>
          <motion.div initial={{ x: 100 }}
            whileInView={{ x: 1 }}
            transition={{ duration: 1, type: "spring", stiffness: 100 }} className="lg:max-w-[65%]">
            <div className="w-full flex flex-col gap-6">
              {ServiceData.slice(0, 6).map((e, i) => (
                <div
                  className="group hover:bg-troo-primary hover:bg-opacity-10 hover:border-t-troo-primary md:gap-5  border-t border-b hover:border-b-0 border-troo-primary border-opacity-20 flex flex-col md:flex-row  items-center py-4  px-0 lg:px-4"
                  key={i}
                >
                  <div className="flex gap-3 items-center md:w-[40%]">
                    <div className=" text-troo-white border border-troo-white group-hover:border-troo-primary group-hover:text-troo-primary rounded-full flex justify-center items-center w-10 h-10 transition-all ease-in-out duration-500">
                      0{e.id}
                    </div>
                    <h4>{e.name} </h4>
                  </div>
                  <div className="flex flex-col md:flex-row justify-between items-center w-full md:gap-8">
                    <p className="  text-center py-5 md:py-0">{e.para}</p>
                    <div className="">
                      <Link
                        to={`/our-services/services-details?${createSearchParams(
                          { id: e.id }
                        )}`}
                        className="flex"
                      >
                        <GoArrowUpRight className="w-10 h-10 p-2 flex justify-center items-center bg-troo-primary bg-opacity-20 group-hover:bg-troo-primary transition-all ease-in-out duration-500  rounded-full" />
                      </Link>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Service;
