import project1 from "../images/project1.png"
import project2 from "../images/project2.png"
import project3 from "../images/project3.png"
import project4 from "../images/project4.png"
import project5 from "../images/project5.png"
import project6 from "../images/project6.png"
import project7 from "../images/project7.png"
import project8 from "../images/project8.png"
import project9 from "../images/project9.png"



export const ProjectData =[
    {
        id : 1,
        img :project1,
        name : "Online Grocery Store",
        sub : "E-commerce Design"
    },
    {
        id : 2,
        img :project2,
        name : "Spa and wellness",
        sub : "Web Development"
    },
    {
        id : 3,
        img :project3,
        name : "Online Wine Shop",
        sub : "E-commerce Design"
    },
    {
        id : 4,
        img :project4,
        name : "Photography Website",
        sub : "Templates Design"
    },
    {
        id : 5,
        img :project5,
        name : "Solar Projects",
        sub : "Web Development"
    },
    {
        id : 6,
        img :project6,
        name : "Yoga Classes",
        sub : "Templates Design"
    },
    {
        id : 7,
        img :project7,
        name : "Online Coffee Shop",
        sub : "E-commerce Design"
    },
    {
        id : 8,
        img :project8,
        name : "Halping Hand",
        sub : "Web Development"
    },
    {
        id : 9,
        img :project9,
        name : "Handyman Services",
        sub : "Templates Design"
    },
]