import caseStudy1 from "../images/caseStudy1.svg";
import caseStudy2 from "../images/caseStudy2.svg";
import caseStudy3 from "../images/caseStudy3.svg";
import caseStudy4 from "../images/caseStudy4.svg";

export const CaseStudyData = [
  {
    id: 1,
    name: "Information Gathering",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    img: caseStudy1,
  },
  {
    id: 2,
    name: "Idea Planing",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    img: caseStudy2,
  },
  {
    id: 3,
    name: "Design Analysis",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    img: caseStudy3,
  },
  {
    id: 4,
    name: "Testing and Launching",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
    img: caseStudy4,
  },
];
