import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ServiceData } from "../Data/ServiceData";
import {
  BsChatDots,
  BsCheckLg,
  BsPerson,
  BsTelephone,
} from "react-icons/bs";
import DetailsImg from "../images/serviceDlts.png";
import { ServiceProcessData } from "../Data/ServiceProcessData";
import { Link } from "react-router-dom";
import detailsSideImg from "../images/serviceDlts2.png";
import Input from "./Input";
import { IoMailOpenOutline } from "react-icons/io5";
import { HiOutlineArrowSmRight } from "react-icons/hi";
import ServiceCategories from "./ServiceCategories ";
import { motion } from "framer-motion";

const ServiceDetails = () => {
  const [picture, setPicture] = useState("");
  const [scarchparam] = useSearchParams();
  const id = scarchparam.get("id");

  useEffect(() => {
    const filData = ServiceData.find((data) => data?.id == id);

    setPicture(filData?.img2);
  }, [id]);

  return (
    <div className="py-12 lg:py-24 w-full">
      <div className="main_container1">
        <div className="flex flex-col lg:flex-row h-full items-start gap-6">
          <div className="w-full lg:w-[70%] flex flex-col items-start gap-5">
            <img
              className="w-full"
              src={picture || DetailsImg}
              alt="DetailsImg"
            />
            <h3>Description</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit
              amet, consectetur notted adipisicing elit sed do eiusmod tempor
              incididunt ut labore et simply free Lorem ipsum dolor sit amet,
              consectetur notted adipisicing elit sed do eiusmod tempor
              incididunt ut labore et simply free text dolore magna aliqua lonm
              andhn.text dolore magna aliqua lonm andhn.notted adipisicing elit
              sed do eiusmod tempor incididunt ut labore et simply free text
              dolore magna aliqua lonm andhn.
            </p>
            <p>
              Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit
              amet, consectetur notted adipisicing elit sed do eiusmod tempor
              incididunt ut labore simply free Lorem ipsum dolor sit amet,
              consectetur notted adipisicing elit sed do eiusmod tempor
              incididunt ut labore et simpl.
            </p>
            <h3>Service Process</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit
              amet, consectetur notted adipisicing elit sed do eiusmod tempor
              incididunt ut labore simply free Lorem ipsum dolor.
            </p>
            <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 relative z-[1]">
              {ServiceProcessData.map((e, i) => (
                <Link
                  className="p-5 flex gap-5 bg-troo-lightpink hover:bg-troo-white px-5 py-8 relative before:w-full group before:h-full before:content-[''] before:top-0 before:left-0 before:bg-troo-primary before:rounded hover:before:top-2 hover:before:left-2 before:absolute before:z-[-1] z-auto before:transition-all before:ease-in-out before:duration-500"
                  key={i}
                >
                  <div>
                    <h3 className="rounded-full group-hover:bg-opacity-100 group-hover:text-troo-white transition-all ease-in-out duration-500  bg-troo-primary bg-opacity-10 w-12 h-12 flex justify-center items-center">
                      0{e.id}
                    </h3>
                  </div>
                  <div className="flex flex-col gap-3">
                    <h4>{e.name}</h4>
                    <p>{e.para}</p>
                  </div>
                </Link>
              ))}
            </div>
            <p>
              Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit
              amet, consectetur notted adipisicing elit sed do eiusmod tempor
              incididunt ut labore simply free Lorem ipsum.
            </p>
            <div className="flex flex-col md:flex-row gap-10 md:items-center w-full">
              <div>
                <img
                  className="w-full"
                  src={detailsSideImg}
                  alt="details img"
                />
              </div>
              <div className="flex flex-col gap-3 mr-5">
                <p className="max-w-[400px] mb-5">
                  Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor and
                  it ji sit amet, consectetur notted adipisicing elit sed do
                  eiusmod tempor incididunt ut labore simply free Lorem.
                </p>
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
                  Easy to Use, Built Technology
                </p>
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
                  Easy to Built onPowerful Technology
                </p>
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
                  Easy to Use, Built onPowerful
                </p>
                <p className="flex items-center gap-2">
                  <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
                  Easy to Use, Built Technology
                </p>
              </div>
            </div>
          </div>
          <div className="w-full md:sticky top-40 lg:w-[30%]  ">
            <ServiceCategories />
            <div className="flex flex-col items-start gap-6 bg-troo-secondary text-troo-white p-8 mb-10">
              <h4 className="relative  pb-3 before:content-[''] before:absolute before:left-0 before:bottom-0 before:w-6 before:h-[3px] before:bg-troo-primary">
                Get A Quote
              </h4>
              <div className="w-full">
                <form className="flex flex-col gap-3 w-full">
                  <Input
                    isservice={true}
                    placeholder="Full Name"
                    icon={<BsPerson />}
                  />

                  <Input
                    isservice={true}
                    placeholder="Email Adress"
                    icon={<IoMailOpenOutline />}
                  />
                  <Input
                    isservice={true}
                    placeholder="Phone No."
                    icon={<BsTelephone />}
                  />
                  <div className="flex md:col-span-2 items-start w-full px-1 py-3 justify-between border-b border-troo-white">
                    <textarea
                      rows={3}
                      placeholder="Message"
                      className="bg-transparent outline-none w-full"
                    />
                    <div className="text-troo-light text-lg">
                      <BsChatDots />
                    </div>
                  </div>
                  <div className="text-center mt-8">
                    <button className="btn1 bg-troo-primary hover:bg-troo-secondary text-troo-white group transition-all ease-in-out duration-500">
                      Get A Quote
                      <HiOutlineArrowSmRight className="w-8 h-8 p-1 flex justify-center items-center bg-troo-secondary group-hover:bg-troo-primary transition-all ease-in-out duration-500  rounded-full" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServiceDetails;
