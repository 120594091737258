import React from 'react'
import { ServiceData } from '../Data/ServiceData'
import { Link, createSearchParams } from 'react-router-dom'
import { HiMiniArrowRight } from 'react-icons/hi2'

const ServiceGrid = () => {
  return (
    <div className='py-12 lg:py-24'>
        <div className='main_container1'>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-7 relative z-[1] ">
          {ServiceData.map((e, i) => (
            <Link to={`/our-services/services-details?${createSearchParams(
              { id: e.id }
            )}`} key={i} className="bg-troo-lightpink hover:bg-troo-white px-5 py-8 flex flex-col gap-3 relative before:w-full group before:h-full before:content-[''] before:top-0 before:left-0 before:bg-troo-primary before:rounded hover:before:top-2 hover:before:left-2 before:absolute before:z-[-1] z-auto before:transition-all before:ease-in-out before:duration-500">
              <div className="rounded-full group-hover:bg-opacity-100 group-hover:text-troo-white transition-all ease-in-out duration-500  bg-troo-primary bg-opacity-10 w-20 h-20 flex justify-center items-center">
            <h2>{e.id}</h2>
              </div>
              <h3>{e.name}</h3>
              <p>{e.para}</p>
              <div className="flex items-center gap-3">
                <p className="font-bold">Read More</p>
                <HiMiniArrowRight />
              </div>
            </Link>
          ))}
        </div>
        </div>
    </div>
  )
}

export default ServiceGrid