import React from "react";
import { Link } from "react-router-dom";
import logo from "../images/Logo.svg";
import Input from "./Input";
import { BsChatDots, BsPerson, BsTelephone } from "react-icons/bs";
import { IoMailOpenOutline } from "react-icons/io5";
import { HiOutlineArrowSmRight } from "react-icons/hi";
import { TbPhoneCall } from "react-icons/tb";
import { FiEdit } from "react-icons/fi";

const DrawerMenu = () => {
  return (
    <div className=" bg-troo-white">
     <div className="p-5">
     <div className="flex justify-center items-center mb-10">
        <img src={logo} alt="logo" />
      </div>
      <p>
        There are many variations of passages of Lorem Ipsum available, but the
        majority have suffered alteration in some form, by injected humour, or
        randomised
      </p>
      <div className="py-5 flex gap-5">
        <div className="flex gap-3">
          <div className=" p-3 flex justify-center items-center rounded">
            <TbPhoneCall className="text-3xl text-troo-primary" />
          </div>
          <div className="text-left">
            <h6>Call Us on</h6>
            <p className="font-bold">+44 123 456 7890</p>
          </div>
        </div>
      </div>
     </div>
      <div className="bg-troo-lightpink py-8 px-5">
        <h3 className="pb-6">Get a Free Quote</h3>
        <div className="w-full">
          <form className="flex flex-col gap-4 w-full">
            <Input placeholder="Full Name" icon={<BsPerson />} />
            <Input placeholder="Email Adress" icon={<IoMailOpenOutline />} />
            <Input placeholder="Phone No." icon={<BsTelephone />} />
            <Input placeholder="Company Name" icon={<FiEdit />} />
                <select
                  className="select w-full bg-transparent rounded-none border-t-0 border-x-0 px-1 font-normal text-troo-light text-opacity-50 text-base outline-none border-b border-troo-secondary 
                 md:col-span-2 items-start  py-3 justify-between"
                >
                  <option disabled selected>
                    Subject
                  </option>
                  <option>Enquiry 1</option>
                  <option>Enquiry 2</option>
                  <option>Enquiry 3</option>
                  <option>Enquiry 4</option>
                </select>
            <div className="flex md:col-span-2 items-start w-full px-1 py-3 justify-between border-b border-troo-secondary">
              <textarea
                rows={3}
                placeholder="Message"
                className="bg-transparent outline-none w-full"
              />
              <div className="text-troo-light text-lg">
                <BsChatDots />
              </div>
            </div>
            <div className=" mt-3">
              <button className="btn1 bg-troo-primary hover:bg-troo-secondary text-troo-white group transition-all ease-in-out duration-500">
              Send a Message
                <HiOutlineArrowSmRight className="w-8 h-8 p-1 flex justify-center items-center bg-troo-secondary group-hover:bg-troo-primary transition-all ease-in-out duration-500  rounded-full" />
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DrawerMenu;
