import React, { useEffect, useState } from "react";
import { BlogData } from "../Data/BlogsData";
import { Link, createSearchParams } from "react-router-dom";

const RelatedBlogs = ({ currentId }) => {
  const [blogList, setBlogList] = useState([]);
  useEffect(() => {
    const start = currentId;
    const end = currentId + 5;

    const relatedBlogs = BlogData.slice(start, end).concat(
      BlogData.slice(0,(end > BlogData.length )?  (end - BlogData.length): 0)
    );
    console.log(relatedBlogs);
    setBlogList(relatedBlogs);
  }, [currentId]);

  if (blogList.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col items-start gap-6 bg-troo-secondary text-troo-white p-8">
      <h4 className="relative mb-5  pb-3 before:content-[''] before:absolute before:left-0 before:bottom-0 before:w-6 before:h-[3px] before:bg-troo-primary">
        Related Blogs
      </h4>
      <div className="flex flex-col gap-6 w-full">
        {blogList.map((e, i) => (
          <Link
            to={`/our-blogs/blog-details?${createSearchParams({ id: e.id })}`}
            key={i}
            className={`flex justify-between gap-4 `}
          >
            <div className="w-[150px]">
              <img className="rounded" src={e.imgd} alt={e.name} />
            </div>
            <div>
              <h6>{e.date}</h6>
              <p className="font-bold max-w-[380px]">{e.name}</p>
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default RelatedBlogs;
