import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { CarrierData } from "../Data/CarrierData";
import { FaCheck } from "react-icons/fa";
import { Link } from "react-router-dom";
import { HiOutlineArrowSmRight } from "react-icons/hi";

const CarrierDetails = () => {
  const [heading, setHeading] = useState("");

  const [scarchparam] = useSearchParams();
  const id = scarchparam.get("id");

  useEffect(() => {
    const filData = CarrierData.find((data) => data?.id == id);
    setHeading(filData?.name);
  }, [id]);
  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        <div className="flex flex-col lg:flex-row h-full items-start gap-8">
          <div className="w-full lg:w-[75%] flex flex-col items-start gap-5">
            <h2>{heading}</h2>
            <div className="flex flex-col sm:flex-row gap-5 lg:gap-10 mb-6">
              <div>
                <p>Location</p>
                <h4>4516 School Street Danbury, CT, Canada</h4>
              </div>
              <div>
                <p>Date</p>
                <h4>24, June 2023</h4>
              </div>
              <div>
                <p>Job Type</p>
                <h4>Full time (Remote)</h4>
              </div>
            </div>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything embarrassing
              hidden in the middle of text. All the Lorem Ipsum generators on
              the Internet tend to repeat predefined chunks as necessary, making
              this the first true generator on the Internet. It uses a
              dictionary of over 200 Latin words, combined with a handful of
              model sentence structures, to generate Lorem Ipsum which looks
              reasonable. The generated Lorem Ipsum is therefore always free
              from repetition, injected humour, or non-characteristic words etc.
            </p>
            <h3>Responsibilities</h3>

            <div className="flex flex-col gap-3 mr-5 w-full">
              <div className="flex  gap-2">
                <div className="pt-1">
                  <FaCheck className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
                </div>
                <p>
                  Collaborate with product engineers, development engineers,
                  researchers and product managers to gather and evaluate UI
                  requirements for new ArcGIS Enterprise software releases
                </p>
              </div>
              <div className="flex gap-2">
                <div className="pt-1">
                  <FaCheck className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
                </div>
                <p>
                  Collaborate with a global team of designers in Esri’s Creative
                  Lab
                </p>
              </div>
              <div className="flex  gap-2">
                <div className="pt-1">
                  <FaCheck className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
                </div>
                <p>
                  Build interactive wireframes and mockups and communicate
                  design ideas and concepts to various stakeholders
                </p>
              </div>
              <div className="flex  gap-2">
                <div className="pt-1">
                  <FaCheck className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
                </div>
                <p> Design simple and easy to use user interfaces</p>
              </div>
              <div className="flex  gap-2">
                <div className="pt-1">
                  <FaCheck className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
                </div>
                <p>
                  Develop a deep understanding of the ArcGIS product ecosystem
                  to influence design decisions
                </p>
              </div>
            </div>

            <h3>Requirements:</h3>
            <div className="flex flex-col gap-3 mr-5 w-full">
              <div className="flex  gap-2">
                <div className="pt-1">
                  <FaCheck className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
                </div>
                <p>
                  2-4 years of relevant experience specializing in visual design
                  for the web
                </p>
              </div>
              <div className="flex gap-2">
                <div className="pt-1">
                  <FaCheck className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
                </div>
                <p>
                  Collaborate with a global team of designers in Esri’s Creative
                  Lab
                </p>
              </div>
              <div className="flex  gap-2">
                <div className="pt-1">
                  <FaCheck className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
                </div>
                <p>Proficiency in design software (such as Figma)</p>
              </div>
              <div className="flex  gap-2">
                <div className="pt-1">
                  <FaCheck className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
                </div>
                <p>Design simple and easy to use user interfaces</p>
              </div>
              <div className="flex  gap-2">
                <div className="pt-1">
                  <FaCheck className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
                </div>
                <p>
                  Strong written and verbal skills to communicate design ideas
                  and rationales
                </p>
              </div>
              <div className="flex  gap-2">
                <div className="pt-1">
                  <FaCheck className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
                </div>
                <p>
                  Proven ability to build and deliver quality software in a team
                  environment
                </p>
              </div>
              <div className="flex  gap-2">
                <div className="pt-1">
                  <FaCheck className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
                </div>
                <p>Effective time management and organization skills</p>
              </div>
              <div className="flex  gap-2">
                <div className="pt-1">
                  <FaCheck className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
                </div>
                <p>
                  Familiarity with ArcGIS Enterprise, ArcGIS Online or other
                  geospatial technology will be a plus
                </p>
              </div>
            </div>
            <h3>Perks and Benefits</h3>
            <div className="flex flex-col gap-3 mr-5 w-full">
              <div className="flex  gap-2">
                <div className="pt-1">
                  <FaCheck className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
                </div>
                <p>
                  99% company paid medical, dental, and vision coverage for
                  employees and dependents (for US employees)
                </p>
              </div>
              <div className="flex gap-2">
                <div className="pt-1">
                  <FaCheck className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
                </div>
                <p>Best-in-class equipment</p>
              </div>
              <div className="flex  gap-2">
                <div className="pt-1">
                  <FaCheck className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
                </div>
                <p>
                  Flexible Spending Account (FSA) and Dependent Care Flexible
                  Spending Account (DCFSA)
                </p>
              </div>
              <div className="flex  gap-2">
                <div className="pt-1">
                  <FaCheck className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
                </div>
                <p>Life, AD&D, Short and Long Term Disability Insurance</p>
              </div>
              <div className="flex  gap-2">
                <div className="pt-1">
                  <FaCheck className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
                </div>
                <p>Home office & technology reimbursement</p>
              </div>
            </div>
            <p>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour, or randomised words which don't look even
              slightly believable. If you are going to use a passage of Lorem
              Ipsum, you need to be sure there isn't anything embarrassing
              hidden in the middle of text. All the Lorem Ipsum generators on
              the Internet tend to repeat predefined chunks as necessary.
            </p>
            <div>
              <Link className="btn1 bg-troo-secondary hover:bg-troo-primary hover:text-troo-white text-troo-white group transition-all ease-in-out duration-500">
                Apply Now!
                <HiOutlineArrowSmRight className="w-8 h-8 p-1 flex justify-center items-center group-hover:bg-troo-secondary bg-troo-primary transition-all ease-in-out duration-500  rounded-full" />
              </Link>
            </div>
          </div>
          <div className="w-full md:sticky top-40 lg:w-[25%]  ">
            <div className="flex flex-col items-start gap-6 bg-troo-lightpink shadow-sm p-6 rounded mb-10">
              <div className="flex flex-col gap-2">
                <p>Blog Categories</p>
                <h4>3.5 Year of Experience</h4>
              </div>
              <div className="flex flex-col gap-2">
                <p>Working Hours</p>
                <h4>09:00 AM to 06:00 PM</h4>
              </div>
              <div className="flex flex-col gap-2">
                <p>Working Days</p>
                <h4>Weekly 5 days (Mon to Fri)</h4>
              </div>
              <div className="flex flex-col gap-2">
                <p>Salary</p>
                <h4>$24k - 65k (Yearly)</h4>
              </div>
              <div className="flex flex-col gap-2">
                <p>Vacancy</p>
                <h4>No of Vacancies: 05</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CarrierDetails;
