


export const ProjectDetailsData = [
    {
        id : 1,
        name :"How do creative agencies grow?",
        para : "Lorem ipsum dolo elitr sit amet, and consectetur adipiscing ipsum dolor."
    },
    {
        id : 2,
        name :"How can digital agencies improve?",
        para : "Lorem ipsum dolo elitr sit amet, and consectetur adipiscing ipsum dolor."
    },
    {
        id : 3,
        name :"Professional translation",
        para : "Lorem ipsum dolo elitr sit amet, and consectetur adipiscing ipsum dolor."
    },
    {
        id : 4,
        name :"What makes a good creative agency?",
        para : "Lorem ipsum dolo elitr sit amet, and consectetur adipiscing ipsum dolor."
    },
    {
        id : 5,
        name :"What makes a good creative agency?",
        para : "Lorem ipsum dolo elitr sit amet, and consectetur adipiscing ipsum dolor."
    },
    {
        id : 6,
        name :"What makes a good creative agency?",
        para : "Lorem ipsum dolo elitr sit amet, and consectetur adipiscing ipsum dolor."
    },
]