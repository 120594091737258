import React from "react";
import { HiOutlineArrowSmRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import heroImg from "../images/heroImg.png";
import { RiListCheck3 } from "react-icons/ri";
import { motion } from "framer-motion";

const Banner = () => {
  return (
    <div className="bg-banner-bg bg-no-repeat bg-cover w-full h-full relative">
      <div className="main_container1 py-12 lg:py-40">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-y-10 gap-8 lg:pb-12">
          <div className="text-troo-white flex flex-col gap-6">
            <motion.p
              initial={{ y: 40 }}
              whileInView={{ y: 1 }}
              transition={{ duration: 1, type: "spring", stiffness: 60 }}
            >
              We Are TRoothemes.com
            </motion.p>
            <motion.h1
              initial={{ y: 40 }}
              whileInView={{ y: 1 }}
              transition={{ duration: 1, type: "spring", stiffness: 60 }}
            >
              We know how to effectively design the user experience
            </motion.h1>
            <motion.h5
              initial={{ y: 40 }}
              whileInView={{ y: 1 }}
              transition={{ duration: 1, type: "spring", stiffness: 60 }}
            >
              A multi award-winning Web Design Agency and top-rated Web Designer
              on Veri Network. We are based in the UK with offices in London and
              Essex.
            </motion.h5>
            <motion.div
              initial={{ x: -100 }}
              whileInView={{ x: 1 }}
              transition={{ duration: 1, type: "spring", stiffness: 100 }}
            >
              <Link className="btn1 bg-troo-primary hover:bg-troo-white hover:text-troo-secondary group transition-all ease-in-out duration-500">
                Contact Us Now
                <HiOutlineArrowSmRight className="w-8 h-8 p-1 flex justify-center items-center bg-troo-secondary group-hover:bg-troo-primary transition-all ease-in-out duration-500  rounded-full" />
              </Link>
            </motion.div>
          </div>
          <div className="ml-0 lg-8 2xl:ml-12 lg:p-10 relative">
            <div className="relative">
              <motion.img
                initial={{ y: -100 }}
                whileInView={{ y: 1 }}
                transition={{ duration: 3, type: "spring", stiffness: 30 }}
                className="w-full "
                src={heroImg}
                alt="heroImg"
              />
              <motion.div initial={{ x: -100 }}
                whileInView={{ x: 1 }}
                transition={{ duration: 3, type: "spring", stiffness: 30 }} className="imgCard absolute left-0 top-32 flex p-5 bg-troo-white gap-5 rounded-2xl w-[200px]">
                <div className="">
                  <RiListCheck3 className="text-2xl text-troo-white flex justify-center items-center w-12 h-12 p-1 bg-troo-primary rounded-xl" />
                </div>
                <div>
                  <div className="font-bold text-xl">2K+</div>
                  <div> Projects</div>
                </div>
              </motion.div>
              <motion.div initial={{ x: 100 }}
                whileInView={{ x: 1 }}
                transition={{ duration: 3, type: "spring", stiffness: 30 }}  className="imgCard absolute lg:right-0 2xl:right-[-80px] top-56 flex p-5 bg-troo-white gap-5 rounded-2xl w-[200px]">
                <div className="">
                  <RiListCheck3 className="text-2xl text-troo-white flex justify-center items-center w-12 h-12 p-1 bg-troo-primary rounded-xl" />
                </div>
                <div>
                  <div className="font-bold text-xl">4.8</div>
                  <div> Satisfaction</div>
                </div>
              </motion.div>
              <motion.div initial={{ y: 100 }}
                whileInView={{ y: 1 }}
                transition={{ duration: 3, type: "spring", stiffness: 30 }} className="imgCard absolute  right-24 bottom-0 flex p-5 bg-troo-white gap-5 rounded-2xl w-[230px]">
                <div className="">
                  <RiListCheck3 className="text-2xl text-troo-white flex justify-center items-center w-12 h-12 p-1 bg-troo-primary rounded-xl" />
                </div>
                <div>
                  <div className="font-bold text-xl">50+</div>
                  <div> Web Designer</div>
                </div>
              </motion.div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
