import React, { useEffect, useState } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import { TeamData } from "../Data/TeamData";
import { HeaderData } from "../Data/HeaderData";
import { ServiceData } from "../Data/ServiceData";
import { BlogData } from "../Data/BlogsData";
import { CarrierData } from "../Data/CarrierData";
import { ProjectData } from "../Data/ProjectData";

const BannerGlobal = () => {
  const [heading, setHeading] = useState("");
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const pathName = location.pathname;

  useEffect(() => {
    window.scrollTo(0, 0);
    const id = Number(searchParams.get("id"));

    const dataMaps = [
      { path: "blog-details", data: BlogData },
      { path: "services-details", data: ServiceData },
      { path: "projects-details", data: ProjectData },
      { path: "team-details", data: TeamData },
      { path: "job-details", data: CarrierData },
    ];

    for (const { path, data } of dataMaps) {
      if (pathName.includes(path) && id) {
        const item = data.find((e) => e.id === id);
        setHeading(item?.name || "");
        return;
      }
    }
    const headerData = HeaderData.find((data) => {
      if (data?.subNav) {
        return data.subNav.find((s) => s.path === pathName);
      } else {
        return data.path === pathName;
      }
    });

    if (headerData?.subNav) {
      const subItem = headerData.subNav.find((s) => s.path === pathName);
      setHeading(subItem?.heading || headerData?.heading || "");
    } else {
      setHeading(headerData?.heading || "");
    }
  }, [searchParams, pathName]);

  useEffect(() => {
    const pathnameParts = location.pathname.split("/").filter((p) => !!p);
    const breadcrumbs = pathnameParts.map((part, i) => ({
      title: part,
      path: `/${pathnameParts.slice(0, i + 1).join("/")}`,
    }));
    setBreadcrumbs(breadcrumbs);
  }, [location]);

  return (
    <div className="top-0 w-full bg-troo-secondary">
      <div className="bg-banner-global bg-no-repeat bg-bottom w-ful h-full py-12 lg:py-24 relative">
        <div className="main_container py-12">
          <div className="flex flex-col justify-center items-center">
            <div className="py-3 px-5 md:px-8 rounded shadow-sm">
              <h2 className="text-troo-white text-center max-w-[700px]">{heading}</h2>
              <ul className="flex flex-wrap gap-1 justify-center px-5 py-3 text-troo-white">
                <Link to="/">Home</Link>
                <span>/</span>
                {breadcrumbs.map((breadcrumb, index) => (
                  <React.Fragment key={index}>
                    <li
                      className={`capitalize text-troo-white ${
                        index === breadcrumbs.length - 1 ? "text-troo-primary" : ""
                      }`}
                    >
                      <Link
                        to={breadcrumb.path}
                        className={`${
                          breadcrumb.path === pathName ? "text-troo-primary" : ""
                        } ${breadcrumb.path === "/faq" ? "uppercase" : ""}`}
                      >
                        {breadcrumb.title.replace(/-/g, " ")}
                      </Link>
                    </li>
                    {index !== breadcrumbs.length - 1 && <span>/</span>}
                  </React.Fragment>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BannerGlobal;