import React from "react";
import { HiOutlineUser } from "react-icons/hi";
import { CiCalendarDate, CiLocationOn } from "react-icons/ci";
import { BsArrowUpLeft, BsCheckLg, BsTag } from "react-icons/bs";
import projectdtls from "../images/projectDetails.png";
import { ProjectDetailsData } from "../Data/ProjectDetailsData";
import { GoArrowUpLeft } from "react-icons/go";
import { HiOutlineArrowUpLeft, HiOutlineArrowUpRight } from "react-icons/hi2";

const ProjectDetails = () => {
  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1 flex flex-col gap-8">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <div className="flex flex-col gap-5">
            <h3>Overview</h3>
            <p>
              Lorem ipsum dolor sit amet, consectetur Lorem ipsum dolor sit
              amet, consectetur notted adipisicing elit sed do eiusmod tempor
              incididunt ut labore et simply free text dolore magna aliqua lonm
              andhn.text dolore magna aliqua lonm andhn.notted adipisicing elit
              sed do eiusmod tempor incididunt ut labore et simply free text
              dolore magna aliqua lonm.
            </p>
            <p>
              incididunt ut labore et simply free text dolore magna aliqua lonm
              andhn.text dolore magna aliqua lonm andhn.notted adipisicing elit
              sed do eiusmod tempor incididunt ut labore et simply free text
              dolore magna aliqua lonm andhn.
            </p>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 gap-6 bg-troo-lightpink w-full p-3 md:p-8">
            <div className="bg-troo-white p-5 flex gap-2">
              <div className="bg-troo-lightpink p-3 flex justify-center items-center rounded">
                <HiOutlineUser className="text-xl text-troo-primary" />
              </div>
              <div>
                <p className="font-bold">Client Name</p>
                <p>James Decastro</p>
              </div>
            </div>
            <div className="bg-troo-white p-5 flex gap-2">
              <div className="bg-troo-lightpink p-3 flex justify-center items-center rounded">
                <BsTag className="text-xl text-troo-primary" />
              </div>
              <div>
                <p className="font-bold">Categories</p>
                <p>Website Design</p>
              </div>
            </div>
            <div className="bg-troo-white p-5 flex gap-2">
              <div className="bg-troo-lightpink p-3 flex justify-center items-center rounded">
                <CiLocationOn className="text-xl text-troo-primary" />
              </div>
              <div>
                <p className="font-bold">Location</p>
                <p>NewYork, USA</p>
              </div>
            </div>
            <div className="bg-troo-white p-5 flex gap-2">
              <div className="bg-troo-lightpink p-3 flex justify-center items-center rounded">
                <CiCalendarDate className="text-xl font-bold text-troo-primary" />
              </div>
              <div>
                <p className="font-bold">Starting Date</p>
                <p>J20 March, 2023</p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <img className="w-full" src={projectdtls} alt="projectDetailsImg" />
        </div>
        <h3>The Challenges</h3>
        <p>
          There are many variations of passages of Lorem Ipsum available, but
          the majority have suffered alteration in some form, by injected
          humour, or randomised words which don't look even slightly
          believable.There are many variations of passages of Lorem Ipsum
          available, but the majority have suffered alteration in some form, by
          injected humour, or randomised words which don't look even slightly
          believable.There are many variations of passages of Lorem Ipsum
          available, but the majority have suffered alteration in some form, by
          injected humour, or randomised words which don't look even slightly
          believable.There are many variations of passages of Lorem Ipsum
          available, but the majority have suffered alteration in some form, by
          injected humour.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-5 w-full">
          <div className="flex flex-col gap-2">
            <p className="flex items-center gap-2">
              <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
              Every lorem ipsum dollr.
            </p>
            <p className="flex items-center gap-2">
              <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
              Using powerful lorem dollr
            </p>
            <p className="flex items-center gap-2">
              <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
              Lorem ipsum dollr sit.
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <p className="flex items-center gap-2">
              <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
              Lorem ipsum abced dollr sit.
            </p>
            <p className="flex items-center gap-2">
              <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
              Every lorem ipsum dollr.
            </p>
            <p className="flex items-center gap-2">
              <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
              Lorem ipsum dollr sit.
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <p className="flex items-center gap-2">
              <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
              Lorem ipsum abced dollr sit.
            </p>
            <p className="flex items-center gap-2">
              <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
              Every lorem ipsum dollr.
            </p>
            <p className="flex items-center gap-2">
              <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
              Lorem ipsum dollr sit.
            </p>
          </div>
          <div className="flex flex-col gap-2">
            <p className="flex items-center gap-2">
              <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
              Lorem ipsum abced dollr sit.
            </p>
            <p className="flex items-center gap-2">
              <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
              Every lorem ipsum dollr.
            </p>
            <p className="flex items-center gap-2">
              <BsCheckLg className="p-1 text-lg flex justify-center items-center w-5 h-5 bg-troo-primary rounded-[50%] text-troo-white " />
              Lorem ipsum dollr sit.
            </p>
          </div>
        </div>
        <h3>The Solutions</h3>
        <p>
          There are many variations of passages of Lorem Ipsum available, but
          the majority have suffered alteration in some form, by injected
          humour, or randomised words which don't look even slightly
          believable.There are many variations of passages of Lorem Ipsum
          available, but the majority have suffered alteration in some form, by
          injected humour, or randomised words which don't look even slightly
          believable.There are many variations of passages of Lorem Ipsum
          available, but the majority have suffered alteration in some form, by
          injected humour, or randomised words which don't look even slightly
          believable.There are many variations of passages of Lorem Ipsum
          available, but the majority have suffered alteration in some form, by
          injected humour.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
          {ProjectDetailsData.map((e, i) => (
            <div className="bg-troo-lightpink p-3 md:p-6 flex gap-3 md:gap-8" key={i}>
              <div>
                <h3 className="rounded-full p-2 w-12 h-12 flex justify-center items-center bg-troo-brown text-troo-primary">
                  0{e.id}
                </h3>
              </div>
              <div>
                <p className="font-bold">{e.name}</p>
                <p>{e.para}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="border-t-2 py-4 flex justify-between gap-5">
          <div className="flex gap-2 cursor-pointer group">
            <HiOutlineArrowUpLeft className="group-hover:text-troo-primary transition-all ease-in-out duration-500"/>
            <div>
              <p>Preview Project</p>
              <p className="font-bold group-hover:text-troo-primary transition-all ease-in-out duration-500">Mobile App Design</p>
            </div>
          </div>
          <div className="flex gap-2 cursor-pointer group">
            <div className="text-right">
              <p>Preview Project</p>
              <p className="font-bold group-hover:text-troo-primary transition-all ease-in-out duration-500">Mobile App Design</p>
            </div>
            <HiOutlineArrowUpRight  className="group-hover:text-troo-primary transition-all ease-in-out duration-500"/>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectDetails;
