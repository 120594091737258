import React from "react";
import logo from "../../images/Logo.svg";
import { Link } from "react-router-dom";
import { HiOutlineArrowSmRight, HiOutlineMail } from "react-icons/hi";
import { TbPhoneCall } from "react-icons/tb";
import useDocumentTitle from "../../Hooks/PageTitle";

const ComingSoonPage = () => {
  useDocumentTitle("TRoo Web Design Agency | Comingsoon Page")
  return (
    <>
      <div className="bg-comingSoon-bg bg-no-repeat w-full py-12 lg:py-20">
        <div className="main_container1">
          <div className="flex flex-col gap-6 justify-between items-center">
            <div>
              <img src={logo} alt="logo" />
            </div>
            <h4>We Are Coming Soon</h4>
            <h2 className="max-w-[500px] text-center">
              Our Website Is Under Construction
            </h2>
            <p className="max-w-[600px] text-center">
              We are working hard to give you a better experience with our new
              impressive site.{" "}
              <span className="text-troo-primary">
                Stay connected, Stay updated!
              </span>
            </p>

            <div className="flex gap-3 md:gap-0 max-w-[600px] flex-wrap relative items-stretch w-full px-1 justify-between ">
              <input
                className=" py-3 block bg-transparent outline-none placeholder:text-troo-secondary border-b border-troo-primary w-[96%]"
                type="email"
                placeholder="Enter your Email"
              />
              <Link className="btn1 bg-troo-primary hover:bg-troo-white text-troo-white hover:text-troo-secondary group transition-all ease-in-out duration-500 sm:absolute right-0">
                Submit Now !
                <HiOutlineArrowSmRight className="w-8 h-8 p-1 flex justify-center items-center bg-troo-secondary group-hover:bg-troo-primary transition-all ease-in-out duration-500  rounded-full" />
              </Link>
            </div>
            <div className="flex flex-col gap-3 text-center">
              <h4 className="font-poppins">
                For more inquiry:
                <span className="text-troo-primary font-viga">
                  contact@troothems.com
                </span>
              </h4>
              <h4 className="font-poppins">
                Call Us any Time:
                <span className="text-troo-primary font-viga">
                  +44 132 456 7890
                </span>
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-troo-secondary py-12 ">
        <div className="main_container1">
          <div className="flex flex-col gap-5 justify-between items-center text-troo-white">
            <h3>Feel Free to Contact with us</h3>
            <div className="text-center ">
              <div className="inline-flex flex-col gap-5 md:flex-row gap-y-3 justify-center md:justify-between p-5  ">
                <div className="flex gap-3">
                  <div className="bg-troo-secondary p-3 flex justify-center items-center rounded">
                    <TbPhoneCall className="text-3xl text-troo-primary" />
                  </div>
                  <div className="text-left">
                    <h6>Call Us on</h6>
                    <p className="font-bold">+44 123 456 7890</p>
                  </div>
                </div>
                <div className="border-l border-troo-white"></div>
                <div className="flex gap-3">
                  <div className="bg-troo-secondary p-3 flex justify-center items-center rounded">
                    <HiOutlineMail className="text-3xl text-troo-primary" />
                  </div>
                  <div className="text-left">
                    <h6>Email Us on</h6>
                    <p className="font-bold">info@troothemes.com</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center">
              <h3>Our Location</h3>
              <p>1901 Thornridge Cir. Shiloh,Hawaii 81063</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ComingSoonPage;
