import { HiOutlineLocationMarker, HiOutlineMailOpen } from 'react-icons/hi';
import { FiPhoneCall } from 'react-icons/fi';


export const ContactData = [
    {
        id : 1,
        icon : <FiPhoneCall/>,
        sub :"Need Help? Call Us on",
        details :"+44 123 456 7890"
    },
    {
        id : 2,
        icon : <HiOutlineMailOpen/>,
        sub :"Email Us",
        details :"info@troothems.com"
    },
    {
        id : 3,
        icon : <HiOutlineLocationMarker/>,
        sub :"Our Office Address",
        details :"4516 School Street Danbury, CT, Canada"
    },
]