import React, { useEffect, useRef } from "react";
import logo from "../images/Logo.svg";
import humburger from "../images/Humberger.svg";
import { RiMenu3Fill } from "react-icons/ri";
import { FiMail, FiPhoneCall, FiUser } from "react-icons/fi";
import { HeaderData } from "../Data/HeaderData";
import { Link } from "react-router-dom";
import NavMenu from "./NavMenu";
import AccordianMenu from "./AccordianMenu";
import { useLocation } from "react-router-dom";
import { HiOutlineArrowSmRight } from 'react-icons/hi';
import DrawerMenu from "./DrawerMenu";

const Header = () => {
  const location = useLocation();
  let arr = location.pathname?.split("/");
  let lastvalue = arr[arr.length - 1].trim().replace(/-/g, " ");

  const navbar_ref = useRef();
  function handleScroll() {
    if (window.pageYOffset > 10 || document.documentElement.scrollTop > 10) {
      if (navbar_ref.current) {
        navbar_ref.current.style.top = "0px";
      }
    } else {
      if (navbar_ref.current) {
        navbar_ref.current.style.top = "-150px";
      }
    }
  }
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <div className="show_nav" ref={navbar_ref}>
        <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
        <div className="main_container1 ">
        <nav className="py-4 lg:py-0">
              <div className="flex justify-between items-center">
                <div className="mr-7 xl:mr-24">
                  <Link to="/">
                    <img src={logo} alt="logo" />
                  </Link>
                </div>
                <div className="lg:flex hidden items-center ">
                  <ul className="flex items-center h-full">
                    {HeaderData.slice(0, 6).map((item, i) => {
                      return <NavMenu key={i} item={item} />;
                    })}
                    <div className="flex gap-5 items-center">
                      <div className="drawer-content ms-5">
                        <label htmlFor="my-drawer-1" className="drawer-button">
                          <img src={humburger} alt="humburger" />
                        </label>
                      </div>
                      <div className="hidden 2xl:flex items-center">
                        {HeaderData.slice(-1).map((e, i) => {
                          return (
                            <Link
                              key={i}
                              to={e.path}
                              className="bg-troo-secondary btn1 text-troo-white  "
                            >
                              {e.title}
                              <HiOutlineArrowSmRight className="w-8 h-8 p-1 flex justify-center items-center bg-troo-primary rounded-full"/>
                            </Link>
                          );
                        })}
                      </div>
                    </div>
                  </ul>
                </div>

                <div className="md:block lg:hidden  drawer-content">
                  <label htmlFor="my-drawer-2">
                    <RiMenu3Fill className="text-[30px] cursor-pointer" />
                  </label>
                </div>
              </div>
            </nav>
        </div>
        <div className="drawer-side z-100">
          <label htmlFor="my-drawer-2" className="drawer-overlay"></label>
          <div className="menu flex flex-col p-4 w-[80%] md:w-80 h-full overflow-scroll  bg-troo-white text-troo-secondary">
            <div className="flex w-full flex-col items-start gap-4 lg:gap-6 2xl:gap-8">
              <div className="flex items-center">
                <Link to="/">
                  <img
                    src={logo}
                    alt="logo"
                    className="w-[200px] md:w-[200px] xl:w-[250px]"
                  />
                </Link>
              </div>
              <div className="flex w-full flex-col items-start gap-2 lg:gap-6 2xl:gap-8">
                {HeaderData.slice(0, 6).map((e, i) => {
                  return !e?.subNav ? (
                    <Link
                      key={i}
                      to={e.path}
                      className={`py-1 flex items-center capitalize text-lg ${
                        e.title === lastvalue ? "text-troo-primary" : ""
                      }`}
                    >
                      {e.title}
                    </Link>
                  ) : (
                    <AccordianMenu title={e.title} subMenu={e.subNav} />
                  );
                })}
              </div>
              <div className="">
                {HeaderData.slice(-1).map((e, i) => {
                  return (
                    <Link
                    key={i}
                    to={e.path}
                    className="bg-troo-secondary btn1 text-troo-white  "
                  >
                    {e.title}
                    <HiOutlineArrowSmRight className="w-8 h-8 p-1 flex justify-center items-center bg-troo-primary rounded-full"/>
                  </Link>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="drawer drawer-end">
        <input id="my-drawer-1" type="checkbox" className="drawer-toggle" />
        <input id="my-drawer-2" type="checkbox" className="drawer-toggle" />
        <header className="relative w-full left-[50%] translate-x-[-50%] z-10">
          <div className="bg-troo-primary">
            <div className="top_header main_container1 lg:flex hidden justify-between items-center flex-wrap pt-2 pb-12 text-troo-white">
              <p className="flex items-center gap-2">
                <FiPhoneCall />
                Hotline: +44 123 456 7890
              </p>
              <p>
                World's Leading Web & Mobile App Developers for{" "}
                <span className="text-troo-secondary underline">Hire</span>
              </p>
              <p className="flex items-center gap-2"><Link to="/carrier">Career</Link> | Need Help</p>
            </div>
          </div>
          <div className="main_container1 left-1/2 transform -translate-x-1/2 w-full lg:top-10 top-0 relative lg:absolute rounded shadow bg-troo-white">
            <nav className="py-4 lg:py-0">
              <div className="flex justify-between items-center">
                <div className="mr-7 xl:mr-24">
                  <Link to="/">
                    <img src={logo} alt="logo" />
                  </Link>
                </div>
                <div className="lg:flex hidden items-center ">
                  <ul className="flex items-center h-full">
                    {HeaderData.slice(0, 6).map((item, i) => {
                      return <NavMenu key={i} item={item} />;
                    })}
                    <div className="flex gap-5 items-center">
                      <div className="drawer-content ms-5">
                        <label htmlFor="my-drawer-1" className="drawer-button">
                          <img src={humburger} alt="humburger" />
                        </label>
                      </div>
                      <div className="hidden 2xl:flex items-center">
                        {HeaderData.slice(-1).map((e, i) => {
                          return (
                            <Link
                              key={i}
                              to={e.path}
                              className="bg-troo-secondary btn1 text-troo-white  "
                            >
                              {e.title}
                              <HiOutlineArrowSmRight className="w-8 h-8 p-1 flex justify-center items-center bg-troo-primary rounded-full"/>
                            </Link>
                          );
                        })}
                      </div>
                    </div>
                  </ul>
                </div>

                <div className="md:block lg:hidden drawer-content">
                  <label htmlFor="my-drawer-2">
                    <RiMenu3Fill className="text-[30px] cursor-pointer" />
                  </label>
                </div>
              </div>
            </nav>
          </div>
        </header>
        <div className="drawer-side z-[9999]">
          <label htmlFor="my-drawer-1" className="drawer-overlay"></label>
          <div className="menu w-96  min-h-full bg-troo-white text-base-content">
          <DrawerMenu/>
          </div>
        </div>
      </div>
    </>
  );
};

export default Header;
