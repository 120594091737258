import React, { useEffect, useState } from "react";
import { BlogData } from "../Data/BlogsData";
import { Link, createSearchParams } from "react-router-dom";
import { HiOutlineArrowSmRight } from "react-icons/hi";
import { motion } from "framer-motion";

const Blogs = ({ isHomePage }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (isHomePage) {
      setData(BlogData.slice(0, 3));
    } else {
      setData(BlogData);
    }
  }, []);
  return (
    <div
      className={
        isHomePage
          ? "bg-troo-lightpink lg:pt-48 pt-12 pb-12 lg:pb-24 relative z-10"
          : "py-12 lg:py-24"
      }
    >
      <div className="main_container1">
        {isHomePage && (
          <div className="flex flex-col gap-5 mb-12">
            <motion.h2
              initial={{ x: -100 }}
              whileInView={{ x: 1 }}
              transition={{ duration: 1, type: "spring", stiffness: 100 }}
              className="max-w-[550px] text-center mx-auto"
            >
              Read the news and blogs
            </motion.h2>
            <motion.p
              initial={{ x: 100 }}
              whileInView={{ x: 1 }}
              transition={{ duration: 1, type: "spring", stiffness: 100 }}
              className="max-w-[750px] text-center mx-auto"
            >
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour.
            </motion.p>
          </div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 relative z-[1] mb-12">
          {data.map((e, i) => (
            <Link
              to={`/our-blogs/blog-details?${createSearchParams({ id: e.id })}`}
              key={i}
              className="flex flex-col gap-3 p-6 relative before:w-full group before:h-full before:content-[''] before:top-0 before:left-0 before:bg-troo-primary hover:before:top-1 hover:before:left-1  before:absolute before:z-[-1] z-auto before:transition-all before:ease-in-out before:duration-500 bg-troo-white"
            >
              <div>
                <p className="text-troo-white bg-troo-primary rounded-2xl inline-flex px-3 py-1">
                  {e.date}
                </p>
              </div>
              <h3 className="2xl:h-[100px]">{e.name}</h3>
              <p>{e.para}</p>
              <div className="border-b border-troo-secondary"></div>
              <div className="flex gap-5 items-center">
                <div className="rounded-full border p-1 border-dashed border-troo-secondary">
                  <img src={e.img} alt={e.name} />
                </div>
                <div>
                  <p>Posted By</p>
                  <h3>{e.pname}</h3>
                </div>
              </div>
            </Link>
          ))}
        </div>
        {isHomePage && (
          <div className="text-center">
            <Link className="btn1 bg-troo-primary hover:bg-troo-secondary text-troo-white group transition-all ease-in-out duration-500">
              Read All News & Blogs
              <HiOutlineArrowSmRight className="w-8 h-8 p-1 flex justify-center items-center bg-troo-secondary group-hover:bg-troo-primary transition-all ease-in-out duration-500  rounded-full" />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Blogs;
