import React from "react";
import { Link } from "react-router-dom";
import logo2 from "../images/Logo2.svg";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
  FaArrowUp
} from "react-icons/fa";
import { HiOutlineArrowSmRight, HiOutlineMailOpen } from "react-icons/hi";
import { BsArrowUpRightCircle } from 'react-icons/bs';


const Footer = () => {
  return (
    <div className="bg-footer-Bg bg-no-repeat bg-cover w-full bg-center h-full pt-12 lg:pt-24">
      <div className="main_container1">
        <Link className=" flex justify-center gap-10 items-center py-10 border-y border-troo-white border-opacity-20 mb-8 lg:mb-16">
          <div className="font-viga font-normal text-3xl lg:text-7xl text-troo-white">
            GET IN <span className="footerText
            ">TOUCH</span>
          </div>
          <BsArrowUpRightCircle className="font-viga font-normal text-2xl lg:text-6xl text-troo-white"/>
        </Link>
        <div className=" grid grid-cols-1 lg:grid-cols-2 gap-7 w-full lg:gap-12 mb-8 lg:mb-16">
          <div className="flex flex-col md:flex-row justify-between gap-10 text-troo-white">
            <div className="flex flex-col gap-5">
              <h3 className=" relative before:contect-[''] before:absolute before:left-0 before:bottom-0 before:bg-troo-primary before:h-[3px] before:w-6 pb-4  ">
                Information
              </h3>
              <p className="md:w-[350px] w-full">
                There are many variations of passages of Lorem Ipsum available,
                but the majority have Lorem Ipsum available suffered alteration
                in some.
              </p>
              <div className="flex items-center gap-3">
                <p className="font-viga">We Are on:</p>
                <FaFacebookF className="w-8 h-8 flex justify-center items-center p-2 bg-troo-white text-troo-secondary rounded-full hover:bg-troo-primary hover:text-troo-white transition-all ease-in-out duration-500 " />
                <FaInstagram className="w-8 h-8 flex justify-center items-center p-2 bg-troo-white text-troo-secondary rounded-full hover:bg-troo-primary hover:text-troo-white transition-all ease-in-out duration-500 " />
                <FaLinkedinIn className="w-8 h-8 flex justify-center items-center p-2 bg-troo-white text-troo-secondary rounded-full hover:bg-troo-primary hover:text-troo-white transition-all ease-in-out duration-500 " />
                <FaTwitter className="w-8 h-8 flex justify-center items-center p-2 bg-troo-white text-troo-secondary rounded-full hover:bg-troo-primary hover:text-troo-white transition-all ease-in-out duration-500 " />
              </div>
            </div>
            <div>
              <h3 className="mb-8 relative before:contect-[''] before:absolute before:left-0 before:bottom-0 before:bg-troo-primary before:h-[3px] before:w-6 pb-4  ">
                Contact Info
              </h3>
              <div className="flex flex-col gap-4">
                <p>4516 School Street Danbury, CT, Canada</p>
                <p>+44 123 456 7890</p>
                <p className="underline text-troo-primary">
                  contact@troothemes.com
                </p>
              </div>
            </div>
          </div>
          <div className="flex md:flex-row flex-col gap-8 justify-around text-troo-white">
            <div>
              <h3 className="mb-8 relative before:contect-[''] before:absolute before:left-0 before:bottom-0 before:bg-troo-primary before:h-[3px] before:w-6 pb-4">
                Our Services
              </h3>
              <nav className="flex flex-col gap-4">
                <Link className="flex items-center">Website Design</Link>
                <Link className="flex items-center">Web Development</Link>
                <Link className="flex items-center">UX/UI Design</Link>
                <Link className="flex items-center">Logo Design</Link>
                <Link className="flex items-center">Graphics Design</Link>
              </nav>
            </div>
            <div className="">
              <h3 className="mb-8 ">Our Newslatter</h3>
              <form className="w-full relative">
                <input
                  type="emil"
                  placeholder="Email"
                  className="bg-transparent border-b border-troo-white pb-2 w-full"
                />
                <HiOutlineMailOpen className="absolute right-0 top-1" />
                <div className="flex items-center gap-2 py-2">
                  <input type="checkbox" />
                  <p>
                    Accept{" "}
                    <span className="text-troo-primary underline">
                      Terms & Conditions
                    </span>
                    .
                  </p>
                </div>
              </form>
              <Link className="mt-5 btn1 bg-troo-primary hover:bg-troo-white hover:text-troo-secondary group transition-all ease-in-out duration-500">
                Contact Us Now
                <HiOutlineArrowSmRight className="w-8 h-8 p-1 flex justify-center items-center bg-troo-secondary group-hover:bg-troo-primary transition-all ease-in-out duration-500  rounded-full" />
              </Link>
            </div>
          </div>
        </div>
        <div className="relative flex flex-col lg:flex-row justify-between items-center gap-y-5 gap-5 text-troo-white py-5 border-y border-troo-white border-opacity-20">
          <Link>
            <img src={logo2} alt="logo" />
          </Link>
          <nav className="flex flex-wrap gap-5">
            <Link>Home</Link>
            <Link>About Us</Link>
            <Link>Our Services</Link>
            <Link>Contact Us</Link>
          </nav>
          <div className="absolute top-[-20px] rounded-full w-10 h-10 left-[50%] translate-x-[-50%] bg-troo-primary text-troo-white flex justify-center items-center"><FaArrowUp className="  "/></div>
        </div>
        <div className="flex flex-col md:flex-row items-center md:justify-between text-troo-white py-3">
          <div className="bg-troo-whitefade1  py-1 rounded">
            <p>Copyright © 2023. troothemes. All rights reserved.</p>
          </div>
          <div className="flex gap-5 bg-troo-whitefade1 px-7 py-1 rounded">
            <p>Terms & Conditions</p>
            <p>Privacy Policy</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
