import React from 'react'
import Layout from '../Components/Layout'
import Banner from '../Components/Banner'
import Abouts from '../Components/Abouts'
import Highlights from '../Components/Highlights'
import Service from '../Components/Service'
import CaseStudy from '../Components/CaseStudy'
import Projects from '../Components/Projects'
import Highlight2 from '../Components/Highlight2'
import Teams from '../Components/Teams'
import Feedback from '../Components/Feedback'
import Highlight3 from '../Components/Highlight3'
import Blogs from '../Components/Blogs'
import Contacts from '../Components/Contacts'
import useDocumentTitle from '../Hooks/PageTitle'

const Home = () => {
  useDocumentTitle("TRoo Web Design Agency - React Js Template")
  return (
    <Layout>
        <Banner/>
        <Abouts isHomePage={true}/>
        <Highlights/>
        <Service/>
        <CaseStudy/>
        <Projects isHomePage={true}/>
        <Highlight2/>
        <Teams isHomePage={true}/>
        <Feedback isHomePage={true}/>
        <Highlight3/>
        <Contacts isHomePage={true}/>
        <Blogs isHomePage={true}/>
    </Layout>
  )
}

export default Home