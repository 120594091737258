import React from "react";
import { HighlightData } from "../Data/HighlightData";
import { Link } from "react-router-dom";
import highlightImg2 from "../images/highlightImg2.png";
import { motion } from "framer-motion";

const Highlight2 = () => {
  return (
    <div className="bg-highlight-bg bg-troo-secondary bg-no-repeat w-full bg-cover py-12 lg:py-24">
      <div className="main_container1">
        <div className="flex flex-col gap-5 mb-12 text-troo-white">
          <motion.h2 initial={{ x: -100 }}
            whileInView={{ x: 1 }}
            transition={{ duration: 1, type: "spring", stiffness: 100 }} className="max-w-[550px] text-center mx-auto">
            Let’s check our latest project we have recently done
          </motion.h2>
          <motion.p initial={{ x: 100 }}
            whileInView={{ x: 1 }}
            transition={{ duration: 1, type: "spring", stiffness: 100 }} className="max-w-[750px] text-center mx-auto">
            There are many variations of passages of Lorem Ipsum available, but
            the majority have suffered alteration in some form, by injected
            humour.
          </motion.p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 text-troo-white">
          <motion.div initial={{ x: -100 }}
            whileInView={{ x: 1 }}
            transition={{ duration: 1, type: "spring", stiffness: 100 }} className="flex flex-col justify-center items-center lg:items-start gap-5 relative z-[1]">
            {HighlightData.slice(0, 3).map((e, i) => (
              <Link
                className="flex bg-troo-secondary gap-5 p-5 relative before:w-full group before:h-full before:content-[''] before:top-0 before:left-0 before:bg-troo-primary hover:before:top-1 hover:before:left-1  before:absolute before:z-[-1] z-auto before:transition-all before:ease-in-out before:duration-500"
                key={i}
              >
                <div>
                <div className="w-12 h-12 rounded-full flex justify-center items-center border-dashed border border-troo-white group-hover:bg-troo-brown transition-all ease-in-out duration-500">
                  {e.icon}
                </div>
                </div>
                <div>
                  <h3>{e.name}</h3>
                  <p className="max-w-[250px]">{e.para}</p>
                </div>
              </Link>
            ))}
          </motion.div>
          <div className="flex items-center justify-center">
            <motion.img initial={{ y: -100 }}
            whileInView={{ y: 1 }}
            transition={{ duration: 1, type: "spring", stiffness: 100 }} className="rounded lg:w-full h-full" src={highlightImg2} alt="highlightImg2" />
          </div>
          <motion.div initial={{ x: 100 }}
            whileInView={{ x: 1 }}
            transition={{ duration: 1, type: "spring", stiffness: 100 }} className="flex flex-col justify-center items-center lg:items-end gap-5 relative z-[1]">
            {HighlightData.slice(3, 6).map((e, i) => (
              <Link
                className="flex bg-troo-secondary gap-5 p-5 relative before:w-full group before:h-full before:content-[''] before:top-0 before:left-0 before:bg-troo-primary hover:before:top-1 hover:before:left-1  before:absolute before:z-[-1] z-auto before:transition-all before:ease-in-out before:duration-500"
                key={i}
              >
                <div>
                <div className="w-12 h-12 rounded-full flex justify-center items-center border-dashed border border-troo-white group-hover:bg-troo-brown transition-all ease-in-out duration-500">
                  {e.icon}
                </div>
                </div>
                <div>
                  <h3>{e.name}</h3>
                  <p className="max-w-[250px]">{e.para}</p>
                </div>
              </Link>
            ))}
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Highlight2;
