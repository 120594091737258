import React from "react";
import img404 from "../../images/404img.png";
import { Link } from "react-router-dom";
import useDocumentTitle from "../../Hooks/PageTitle";
import { HiOutlineArrowSmRight } from "react-icons/hi";

const Error404Page = () => {
  useDocumentTitle("TRoo Web Design Agency | Error404");
  return (
    <div className="bg-error-bg w-full bg-no-repeat bg-center py-12 lg:py-44">
      <div className="main_container1 flex justify-center">
        <div className="flex flex-col text-center gap-9">
          <div className="pt-24">
            <img
              className="lg:max-w-[400px]   mx-auto"
              src={img404}
              alt={img404}
            />
          </div>
          <h2>Page Not Found</h2>
          <h3 className="max-w-[800px] text-center m-auto">
            The page you look for does not exist or another error occured please
            kindly refreash or retun to home page.
          </h3>
          <div>
            <Link
              to="/"
              className="btn1 bg-troo-primary hover:bg-troo-secondary text-troo-white group transition-all ease-in-out duration-500"
            >
              Back to Homepage
              <HiOutlineArrowSmRight className="w-8 h-8 p-1 flex justify-center items-center bg-troo-secondary group-hover:bg-troo-primary transition-all ease-in-out duration-500  rounded-full" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Error404Page;
