import React from 'react'
import Layout from '../Components/Layout'
import BannerGlobal from '../Components/BannerGlobal'
import Contacts from '../Components/Contacts'
import useDocumentTitle from '../Hooks/PageTitle'

const ContactsPage = () => {
  useDocumentTitle("TRoo Web Design Agency | Contact Page")
  return (
   <Layout>
    <BannerGlobal/>
    <Contacts isContact={true}/>
   </Layout>
  )
}

export default ContactsPage