import React from 'react'
import Layout from '../Components/Layout'
import BannerGlobal from '../Components/BannerGlobal'
import Abouts from '../Components/Abouts'
import Highlights from '../Components/Highlights'
import Highlight2 from '../Components/Highlight2'
import Teams from '../Components/Teams'
import Feedback from '../Components/Feedback'
import Highlight3 from '../Components/Highlight3'
import useDocumentTitle from '../Hooks/PageTitle'


const AboutPage = () => {
  useDocumentTitle("TRoo Web Design Agency | About Page")
  return (
    <Layout>
        <BannerGlobal/> 
        <Abouts/>
        <Highlights/>
        <Highlight2/>
        <Teams/>
        <Feedback/>
        <Highlight3/>
    </Layout>
  )
}

export default AboutPage