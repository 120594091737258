import blog1 from "../images/blog1.png";
import blogD1 from "../images/blogD1.png"
import blogD2 from "../images/blogD2.png"
import blogD3 from "../images/blogD3.png"
import blogD4 from "../images/blogD4.png"
import blogD5 from "../images/blogD5.png"


export const BlogData = [
  {
    id: 1,
    img: blog1,
    imgd : blogD1,
    name: "10+ Best Tools & Resources for Web Designers and Agencies",
    para : "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some.",
    pname :"Renny Young",
    date : "05 APR, 2023"
  },
  {
    id: 2,
    img: blog1,
    imgd : blogD2,
    name: "24 Best Sci-Fi Fonts for Futuristic Designs for 2023",
    para : "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some.",
    pname :"Paige Turner",
    date : "10 APR, 2023"
  },
  {
    id: 3,
    img: blog1,
    imgd : blogD3,
    name: "40+ Best Fonts for Logo Design: Beautiful Typography Ideas to Inspire You (2023)",
    para : "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some.",
    pname :"Harold Franky",
    date : "05 jun, 2023"
  },
  {
    id: 4,
    img: blog1,
    imgd : blogD4,
    name: "Fall in Love with Material Design - 10 Resources",
    para : "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some.",
    pname :"Helen Stefone",
    date : "05 APR, 2023"
  },
  {
    id: 5,
    img: blog1,
    imgd : blogD5,
    name: "Best WooCommerce Themes for the Perfect Online Store Design (2023)",
    para : "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some.",
    pname :"Vicki Wiza",
    date : "05 may, 2023"
  },
  {
    id: 6,
    img: blog1,
    imgd : blogD1,
    name: "Ecommerce Website Designs – The 60+ Top Ecommerce Websites 2023 Edition",
    para : "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some.",
    pname :"Renny Young",
    date : "10 feb, 2023"
  },
  {
    id: 7,
    img: blog1,
    imgd : blogD2,
    name: "Easy and Quick Way to Create Products With Your Designs",
    para : "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some.",
    pname :"Brad Frami",
    date : "05 APR, 2023"
  },
  {
    id: 8,
    img: blog1,
    imgd : blogD3,
    name: "The Best Layout Design Hierarchy for Your Homepage to Grab More Customers and Sales",
    para : "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some.",
    pname :"Madeline Kulas",
    date : "05 APR, 2023"
  },
  {
    id: 9,
    img: blog1,
    imgd : blogD4,
    name: "Best Free Software for Designers and Developers for 2023s",
    para : "There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some.",
    pname :"Dean Heller",
    date : "05 APR, 2023"
  },
];
