import React from "react";
import { FaqData } from "../Data/FAQsData";
import Accordian from "./Accordian";
import { TbPhoneCall } from "react-icons/tb";
import { HiOutlineMail } from "react-icons/hi";

const FAQs = () => {
  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        <div className="text-center mb-12">
          <div className='className="mb-8 text-center inline-flex flex-col lg:flex-row gap-5 py-2 px-5 rounded-3xl lg:rounded-[100px] bg-troo-secondary text-troo-white'>
            <p className="px-5 py-3 rounded-[100px] border inline-flex justify-center border-troo-white hover:bg-troo-primary transition-all ease-in-out duration-500">
              General FAQ’s
            </p>
            <p className="px-5 py-3 rounded-[100px] border inline-flex justify-center border-troo-white hover:bg-troo-primary transition-all ease-in-out duration-500">
              Trust and Safety
            </p>
            <p className="px-5 py-3 rounded-[100px] border inline-flex justify-center border-troo-white hover:bg-troo-primary transition-all ease-in-out duration-500">
              Our Services
            </p>
            <p className="px-5 py-3 rounded-[100px] border inline-flex justify-center border-troo-white hover:bg-troo-primary transition-all ease-in-out duration-500">
              Payment FAQ
            </p>
            <p className="px-5 py-3 rounded-[100px] border inline-flex justify-center border-troo-white hover:bg-troo-primary transition-all ease-in-out duration-500">
              Work Process
            </p>
          </div>
        </div>
        <div className="flex flex-col gap-5 items-start relative z-[1]">
          {FaqData.map((data, i) => {
            return <Accordian key={i} title={data.name} desc={data.para} />;
          })}
        </div>
        <div className="text-center mt-12">
          <p className=" ">Don’t find answer? </p>
          <h3 className="text-2xl text-troo-primary">Contact with us on</h3>
        </div>
        <div className="text-center mt-6">
          <div className="inline-flex flex-col gap-5 md:flex-row gap-y-3 justify-center md:justify-between p-5 bg-troo-lightpink ">
            <div className="flex gap-3">
              <div className="bg-troo-secondary p-3 flex justify-center items-center rounded">
                <TbPhoneCall className="text-xl text-troo-white" />
              </div>
              <div className="text-troo-secondary">
                <h6>Call Us on</h6>
                <p className="font-bold">+44 123 456 7890</p>
              </div>
            </div>
            <div className="border-l border-troo-secondary"></div>
            <div className="flex gap-3">
              <div className="bg-troo-secondary p-3 flex justify-center items-center rounded">
                <HiOutlineMail className="text-xl text-troo-white" />
              </div>
              <div className="text-troo-secondary">
                <h6>Email Us on</h6>
                <p className="font-bold">info@troothemes.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQs;
