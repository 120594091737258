import React from "react";
import Input from "./Input";
import {
  BsChatDots,
  BsPatchCheck,
  BsPerson,
  BsTelephone,
} from "react-icons/bs";
import { IoMailOpenOutline } from "react-icons/io5";
import { HiOutlineArrowSmRight } from "react-icons/hi";
import { FiEdit } from "react-icons/fi";
import spiralArrow from "../images/spiralArrow.svg";
import { ContactData } from "../Data/ContactData";
import { Link } from "react-router-dom";
import Maps from "./Maps";
import { motion } from "framer-motion";

const Contacts = ({ isHomePage, isContact }) => {
  return (
    <div className={`${isContact ? "pt-12 lg:pt-24" : ""} `}>
      <div
        className={`main_container1 ${
          isHomePage
            ? " shadow-contact py-16 lg:mb-[-100px] relative bg-troo-white z-[20]"
            : ""
        }`}
      >
        {isContact && (
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 relative z-[1] mb-10">
            {ContactData.map((e, i) => (
              <Link className="flex items-center gap-3 md:gap-8 p-3 md:p-5 bg-troo-lightpink  relative before:w-full group before:h-full before:content-[''] before:top-0 before:rounded before:left-0 before:bg-troo-primary hover:before:top-1 hover:before:left-1  before:absolute before:z-[-1] z-auto before:transition-all before:ease-in-out before:duration-500">
                <div>
                <div className="md:p-4 p-2 bg-troo-secondary flex items-center justify-center rounded md:text-3xl text-troo-white">{e.icon}</div>
                </div>
                <div>
                  <p>{e.sub}</p>
                  <h3>{e.details}</h3>
                </div>
              </Link>
            ))}
          </div>
        )}
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
          <motion.div initial={{ x: -100 }}
            whileInView={{ x: 1 }}
            transition={{ duration: 1, type: "spring", stiffness: 100 }} className="flex flex-col gap-5">
            <div className="flex gap-5 text-center">
              <p className="max-w-[80px]">Get a free quotes</p>
              <img src={spiralArrow} alt="spiralArrow" />
            </div>
            <h2 className="max-w-[500px]">
              Have Any Project in Mind? Feel Free Contact With Us.
            </h2>
            <p className="max-w-[500px]">
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour Lorem Ipsum available.
            </p>
            <div className="md:p-8 p-3 flex gap-5 bg-troo-primary bg-opacity-20 max-w-[500px]">
              <div>
                <BsPatchCheck className="p-3 bg-troo-primary w-12 rounded text-troo-white h-12" />
              </div>
              <div className="flex flex-col gap-2">
                <p>
                  Choose a plan to save, unique service options, and to view
                  many additional services
                </p>
                <p className="text-troo-primary underline font-bold">
                  View Our Pricing Plans
                </p>
              </div>
            </div>
          </motion.div>
          <motion.div initial={{ x: 100 }}
            whileInView={{ x: 1 }}
            transition={{ duration: 1, type: "spring", stiffness: 100 }}>
            <form className="w-full  flex flex-col items-start gap-14">
              <h3>Request an estimate</h3>
              <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-6">
                <Input placeholder="Full Name" icon={<BsPerson />} />

                <Input
                  placeholder="Email Adress"
                  icon={<IoMailOpenOutline />}
                />
                <Input placeholder="Phone No." icon={<BsTelephone />} />
                <Input placeholder="Company Name" icon={<FiEdit />} />
                <select
                  className="select w-full bg-transparent rounded-none border-t-0 border-x-0 px-1 font-normal text-troo-light text-opacity-50 text-base outline-none border-b border-troo-secondary 
                 md:col-span-2 items-start  py-3 justify-between"
                >
                  <option disabled selected>
                    Subject
                  </option>
                  <option>Enquiry 1</option>
                  <option>Enquiry 2</option>
                  <option>Enquiry 3</option>
                  <option>Enquiry 4</option>
                </select>

                <div className="flex md:col-span-2 items-start w-full px-1 py-3 justify-between border-b border-troo-secondary">
                  <textarea
                    rows={5}
                    placeholder="Message"
                    className="bg-transparent outline-none w-full"
                  />
                  <div className="text-troo-light text-lg">
                    <BsChatDots />
                  </div>
                </div>
              </div>
              <button className="btn1 bg-troo-primary hover:bg-troo-secondary text-troo-white group transition-all ease-in-out duration-500">
                Send a Message
                <HiOutlineArrowSmRight className="w-8 h-8 p-1 flex justify-center items-center bg-troo-secondary group-hover:bg-troo-primary transition-all ease-in-out duration-500  rounded-full" />
              </button>
            </form>
          </motion.div>
        </div>
      </div>
      {
        isContact && (
         <Maps/>
        )
      }
    </div>
  );
};

export default Contacts;
