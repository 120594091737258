import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import TeamDetails from '../../Components/TeamDetails'
import useDocumentTitle from '../../Hooks/PageTitle'

const TeamDetailsPage = () => {
  useDocumentTitle("TRoo Web Design Agency | Teams Details Page")
  return (
   <Layout>
    <BannerGlobal/>
    <TeamDetails/>
   </Layout>
  )
}

export default TeamDetailsPage