import React from 'react'

const PopularTags = () => {
    const tags = ["UX/UI", "Website Design", "Website", "Web Develop", "Hosting", "App Design", "Maintence", "Email Template"];
    return (
        <div className="bg-troo-secondary text-troo-white p-6 w-full mb-10">
          <h4 className="relative mb-5  pb-3 before:content-[''] before:absolute before:left-0 before:bottom-0 before:w-6 before:h-[3px] before:bg-troo-primary">Popular Tags</h4>
          <div className="flex flex-wrap gap-2 w-full">
            {tags.map(tag => (
              <p key={tag} className="p-2 bg-troo-fadblue hover:bg-troo-primary text-troo-white rounded transition-all ease-in-out duration-500">{tag}</p>
            ))}
          </div>
        </div>
      );
}

export default PopularTags