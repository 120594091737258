import React from 'react'

const Maps = () => {
  return (
    <div className='map mt-16'>
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d7343.710423642854!2d72.49508733426413!3d23.029087824997855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x395e9b278e046fa5%3A0xd837def63555f791!2sTRooInbound%20Private%20Limited!5e0!3m2!1sen!2sin!4v1687782488933!5m2!1sen!2sin"
          width="100%"
          height="500"
          style={{ border: "0" }}
          allowfullscreen=""
          loading="lazy"
          title="gmap"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
        </div>
  )
}

export default Maps