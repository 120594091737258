import { IoChatbubblesOutline } from "react-icons/io5";
import { AiOutlinePartition } from "react-icons/ai";
import { BiCoinStack, BiLock, BiWinkSmile } from "react-icons/bi";
import { FiHeadphones } from "react-icons/fi";




export const HighlightData = [
  {
    id: 1,
    name: "Expert Consultants",
    para: "There are many and variations of passages also it of Lorem Ipsum available.",
    icon: <IoChatbubblesOutline />,
  },
  {
    id: 2,
    name: "Save Money",
    para: "There are many and variations of passages also it of Lorem Ipsum available.",
    icon: <BiCoinStack />,
  },
  {
    id: 3,
    name: "24x7 Support Team",
    para: "There are many and variations of passages also it of Lorem Ipsum available.",
    icon: <FiHeadphones />,
  },
  {
    id: 4,
    name: "Easy Process",
    para: "There are many and variations of passages also it of Lorem Ipsum available.",
    icon: <AiOutlinePartition/>
  },
  {
    id: 5,
    name: "High Security",
    para: "There are many and variations of passages also it of Lorem Ipsum available.",
    icon: <BiLock />,
  },
  {
    id: 6,
    name: "Creative Design",
    para: "There are many and variations of passages also it of Lorem Ipsum available.",
    icon: <BiWinkSmile />,
  },
];
