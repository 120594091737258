import React, { useEffect, useState } from "react";
import { FeedBackData } from "../Data/FeedbackData";
import { Link } from "react-router-dom";
import { FaStar } from "react-icons/fa";
import { motion } from "framer-motion";

const Feedback = ({ isHomePage }) => {
  const [data, setData] = useState([]);
  useEffect(() => {
    if (isHomePage) {
      setData(FeedBackData.slice(0, 4));
    } else {
      setData(FeedBackData);
    }
  }, []);

  return (
    <div className={`py-12 lg:py-24  ${isHomePage ? "bg-feedback-bg bg-no-repeat bg-cover bg-troo-lightpink" : ""}`}>
      <div className="main_container1">
        {isHomePage && (
          <div className="flex flex-col gap-5 mb-12">
            <motion.h2 initial={{ x: -100 }}
            whileInView={{ x: 1 }}
            transition={{ duration: 1, type: "spring", stiffness: 100 }} className="max-w-[550px] text-center mx-auto">
              What our valuable client say about our services
            </motion.h2>
            <motion.p initial={{ x: 100 }}
            whileInView={{ x: 1 }}
            transition={{ duration: 1, type: "spring", stiffness: 100 }} className="max-w-[750px] text-center mx-auto">
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour.
            </motion.p>
          </div>
        )}
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 relative z-[1] mb-10">
          {data.map((e, i) => (
            <Link
              key={i}
              className="flex flex-col-reverse md:flex-col gap-3 p-8 bg-troo-lightpink relative before:w-full group before:h-full before:content-[''] before:top-0 before:left-0 before:bg-troo-primary hover:before:top-1 hover:before:left-1  before:absolute before:z-[-1] z-auto before:transition-all before:ease-in-out before:duration-300"
            >
              <p>{e.para}</p>
              <div className="flex lg:flex-row flex-col lg:items-center gap-5 justify-between">
                <div className="flex gap-5 items-center">
                  <div>
                    <img src={e.img} alt={e.name} />
                  </div>
                  <div>
                    <h3>{e.name}</h3>
                    <h6>{e.sub}</h6>
                  </div>
                </div>
            <div>
            <div className="inline-flex gap-2 text-troo-primary bg-troo-brown p-3">
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                  <FaStar />
                </div>
            </div>
              </div>
            </Link>
          ))}
        </div>
        {isHomePage && (
          <div className="text-center">
            <p>
              Want to see more review
              <span className="font-bold">Click Here</span>
            </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default Feedback;
