import React from 'react'
import { ServiceData } from '../Data/ServiceData'
import { Link } from 'react-router-dom'
import { BsArrowRight } from 'react-icons/bs'

const ServiceCategories  = () => {
  return (
    <div className="flex flex-col items-start gap-6 bg-troo-secondary text-troo-white p-8 mb-10">
              <h4 className="relative  pb-3 before:content-[''] before:absolute before:left-0 before:bottom-0 before:w-6 before:h-[3px] before:bg-troo-primary">
                Service Categories
              </h4>
              <div className="flex flex-col gap-3 w-full">
                {ServiceData.slice(0, 9).map((e, i) => (
                  <Link
                    key={i}
                    className="hover:text-troo-primary transition-all ease-in-out duration-500 bg-troo-fadblue flex gap-2 justify-between items-center w-full cursor-pointer"
                  >
                    <p className="flex gap-3">
                      <span> 0{e.id}</span>
                      {e.name}
                    </p>
                    <BsArrowRight />
                  </Link>
                ))}
              </div>
            </div>
  )
}

export default ServiceCategories 