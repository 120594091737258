import React, { useEffect, useState } from "react";
import teamDetailsImg from "../images/teamDetailsImg.png";
import {
  FaFacebookF,
  FaInstagram,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";
import { useSearchParams } from "react-router-dom";
import { TeamData } from "../Data/TeamData";

const TeamDetails = () => {
  const [heading, setHeading] = useState("");
  const [picture, setPicture] = useState("");
  const [sub, setSub] = useState("");
  const [scarchparam] = useSearchParams();
  const id = scarchparam.get("id");
  useEffect(() => {
    const filData = TeamData.find((data) => data?.id == id);
    setPicture(filData?.img);
    setHeading(filData?.name);
    setSub(filData?.sub);
  }, [id]);

  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        <div className="flex flex-col md:flex-row  gap-10 xl:gap-20 mb-10">
          <div className=" ">
            <img
              className="w-full lg:w-[500px] lg:h-[400px] "
              src={picture || teamDetailsImg}
              alt={teamDetailsImg}
            />
          </div>
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-5">
              <div className="flex flex-col gap-3">
                <h4>{heading || "Paige Turner"} </h4>
                <p>{sub || "UX/UI Designer"}</p>
              </div>
              <p>
                <span className="font-bold">Responsibility : </span>
                {sub || "UX/UI Designer"}
              </p>
              <p>
                <span className="font-bold">Experience: : </span>
                10+ Year
              </p>
              <p>
                <span className="font-bold">Application Use : </span>
                Figma, Photoshop, Illustrator
              </p>
              <div className="mt-20">
                <p className="font-bold mb-3">Social Media</p>
                <div className="inline-flex p-3 rounded-3xl gap-3 bg-troo-secondary">
                  <FaFacebookF className="rounded-full w-8 p-2  h-8 bg-troo-white flex justify-center items-center hover:bg-troo-primary hover:text-troo-white transition-all ease-in-out duration-300" />
                  <FaInstagram className="rounded-full w-8 p-2  h-8 bg-troo-white flex justify-center items-center hover:bg-troo-primary hover:text-troo-white transition-all ease-in-out duration-300" />
                  <FaLinkedinIn className="rounded-full w-8 p-2  h-8 bg-troo-white flex justify-center items-center hover:bg-troo-primary hover:text-troo-white transition-all ease-in-out duration-300" />
                  <FaTwitter className="rounded-full w-8 p-2  h-8 bg-troo-white flex justify-center items-center hover:bg-troo-primary hover:text-troo-white transition-all ease-in-out duration-300" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <h3>Personal Info</h3>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </p>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially.
          </p>
          <h3>Career Guidelines</h3>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum.
          </p>
          <h3>Professional Skills</h3>
          <p>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting.
          </p>
          <div className="flex flex-col justify-around">
            <div className="flex flex-col gap-3 mb-5">
              <h4>UX/UI Design</h4>
              <div className="h-1 w-full bg-troo-whitefade">
                <div className="h-1 w-[90%] bg-troo-primary"></div>
              </div>
            </div>
            <div className="flex flex-col gap-4 mb-5">
              <h4>Frontend </h4>
              <div className="h-1 w-full bg-troo-whitefade">
                <div className="h-1 w-[70%] bg-troo-primary"></div>
              </div>
            </div>
            <div className="flex flex-col gap-3">
              <h4>Backend</h4>
              <div className="h-1 w-full bg-troo-whitefade">
                <div className="h-1 w-[80%] bg-troo-primary"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamDetails;
