import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import ServiceGrid from '../../Components/ServiceGrid'
import useDocumentTitle from '../../Hooks/PageTitle'

const ServicePage = () => {
  useDocumentTitle("TRoo Web Design Agency | Service Page")
  return (
   <Layout>
    <BannerGlobal/>
    <ServiceGrid/>
   </Layout>
  )
}

export default ServicePage