import team1 from "../images/team1.png"
import team2 from "../images/team2.png"
import team3 from "../images/team3.png"
import team4 from "../images/team4.png"
import team5 from "../images/team5.png"
import team6 from "../images/team6.png"
import team7 from "../images/team7.png"
import team8 from "../images/team8.png"
import team9 from "../images/team9.png"
import team10 from "../images/team10.png"
import team11 from "../images/team11.png"
import team12 from "../images/team12.png"
import {
    FaFacebookF,
    FaInstagram,
    FaTwitter,
    FaLinkedinIn,
  } from "react-icons/fa";


export const TeamData =[
    {
        id :1,
        img : team1,
        name :"Paige Turner",
        sub : "CEO & Founder",
        social: [
            {
              icon: <FaFacebookF />,
              path: "",
            },
            {
              icon: <FaInstagram />,
              path: "",
            },
            {
              icon: <FaTwitter />,
              path: "",
            },
            {
              icon: <FaLinkedinIn />,
              path: "",
            },
          ],
    },
    {
        id :2,
        img : team2,
        name :"Harold Franky",
        sub : "Project Manager",
        social: [
            {
              icon: <FaFacebookF />,
              path: "",
            },
            {
              icon: <FaInstagram />,
              path: "",
            },
            {
              icon: <FaTwitter />,
              path: "",
            },
            {
              icon: <FaLinkedinIn />,
              path: "",
            },
          ],
    },
    {
        id :3,
        img : team3,
        name :"Helen Stefone",
        sub : "Project Coordinator",
        social: [
            {
              icon: <FaFacebookF />,
              path: "",
            },
            {
              icon: <FaInstagram />,
              path: "",
            },
            {
              icon: <FaTwitter />,
              path: "",
            },
            {
              icon: <FaLinkedinIn />,
              path: "",
            },
          ],
    },
    {
        id :4,
        img : team4,
        name :"Eddie Smith",
        sub : "UX/UI Design",
        social: [
            {
              icon: <FaFacebookF />,
              path: "",
            },
            {
              icon: <FaInstagram />,
              path: "",
            },
            {
              icon: <FaTwitter />,
              path: "",
            },
            {
              icon: <FaLinkedinIn />,
              path: "",
            },
          ],
    },
    {
        id :5,
        img : team5,
        name :"Vicki Wiza",
        sub : "UX/UI Design",
        social: [
            {
              icon: <FaFacebookF />,
              path: "",
            },
            {
              icon: <FaInstagram />,
              path: "",
            },
            {
              icon: <FaTwitter />,
              path: "",
            },
            {
              icon: <FaLinkedinIn />,
              path: "",
            },
          ],
    },
    {
        id :6,
        img : team6,
        name :"Brad Frami",
        sub : "Web Designer",
        social: [
            {
              icon: <FaFacebookF />,
              path: "",
            },
            {
              icon: <FaInstagram />,
              path: "",
            },
            {
              icon: <FaTwitter />,
              path: "",
            },
            {
              icon: <FaLinkedinIn />,
              path: "",
            },
          ],
    },
    {
        id :7,
        img : team7,
        name :"John Anderson",
        sub : "Team Leader",
        social: [
            {
              icon: <FaFacebookF />,
              path: "",
            },
            {
              icon: <FaInstagram />,
              path: "",
            },
            {
              icon: <FaTwitter />,
              path: "",
            },
            {
              icon: <FaLinkedinIn />,
              path: "",
            },
          ],
    },
    {
        id :8,
        img : team8,
        name :"Dean Heller",
        sub : "Web Designer",
        social: [
            {
              icon: <FaFacebookF />,
              path: "",
            },
            {
              icon: <FaInstagram />,
              path: "",
            },
            {
              icon: <FaTwitter />,
              path: "",
            },
            {
              icon: <FaLinkedinIn />,
              path: "",
            },
          ],
    },
    {
        id :9,
        img : team9,
        name :"Madeline Kulas",
        sub : "Web Designer",
        social: [
            {
              icon: <FaFacebookF />,
              path: "",
            },
            {
              icon: <FaInstagram />,
              path: "",
            },
            {
              icon: <FaTwitter />,
              path: "",
            },
            {
              icon: <FaLinkedinIn />,
              path: "",
            },
          ],
    },
    {
        id :10,
        img : team10,
        name :"Helen Stefone",
        sub : "Web Designer",
        social: [
            {
              icon: <FaFacebookF />,
              path: "",
            },
            {
              icon: <FaInstagram />,
              path: "",
            },
            {
              icon: <FaTwitter />,
              path: "",
            },
            {
              icon: <FaLinkedinIn />,
              path: "",
            },
          ],
    },
    {
        id :11,
        img : team11,
        name :"Jasmine Mills",
        sub : "CEO & Founder",
        social: [
            {
              icon: <FaFacebookF />,
              path: "",
            },
            {
              icon: <FaInstagram />,
              path: "",
            },
            {
              icon: <FaTwitter />,
              path: "",
            },
            {
              icon: <FaLinkedinIn />,
              path: "",
            },
          ],
    },
    {
        id :12,
        img : team12,
        name :"Steven Carter",
        sub : "Web Designer",
        social: [
            {
              icon: <FaFacebookF />,
              path: "",
            },
            {
              icon: <FaInstagram />,
              path: "",
            },
            {
              icon: <FaTwitter />,
              path: "",
            },
            {
              icon: <FaLinkedinIn />,
              path: "",
            },
          ],
    },
   
]