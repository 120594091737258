import React, { useEffect, useState } from "react";
import { TeamData } from "../Data/TeamData";
import { Link, createSearchParams } from "react-router-dom";
import { HiOutlineArrowSmRight } from "react-icons/hi";
import { motion } from "framer-motion";

const Teams = ({ isHomePage, isTeamPage }) => {
  const [data, setData] = useState([]);

  useEffect(() => {
    if (isHomePage) {
      setData(TeamData.slice(0, 4));
    } else {
      setData(TeamData);
    }
  }, []);
  return (
    <div className="py-12 lg:py-24">
      <div className="main_container1">
        {isHomePage && (
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-10 gap-y-6 mb-10">
            <div>
              <motion.h2 initial={{ x: -100 }}
            whileInView={{ x: 1 }}
            transition={{ duration: 1, type: "spring", stiffness: 100 }} className="lg:max-w-[500px]">
                Let’s Meet with our expert team member
              </motion.h2>
            </div>
            <div className="flex justify-end ">
              <motion.p initial={{ x: 100 }}
            whileInView={{ x: 1 }}
            transition={{ duration: 1, type: "spring", stiffness: 100 }} className=" lg:max-w-[550px] pl-10 relative before:content-[''] before:absolute before:w-24 before:h-24 before:left-0 before:top-0 before:bg-troo-primary before:opacity-10 before:rounded-full after:content-[''] after:absolute after:w-24 after:h-24 after:border after:border-troo-secondary after:border-opacity-20 after:rounded-full after:left-[-15px] after:top-[-10px]">
                There are many variations of passages of Lorem Ipsum available,
                but the majority have suffered alteration in some form, by
                injected humour.
              </motion.p>
            </div>
          </div>
        )}
        <motion.div initial={{ y: -100 }}
            whileInView={{ y: 1 }}
            transition={{ duration: 1, type: "spring", stiffness: 100 }} className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-8 relative z-[1]">
          {data.map((e, i) => (
            <Link
              to={`/our-team/our-team-details?${createSearchParams({
                id: e.id,
              })}`}
              className="relative group before:w-full before:h-full before:content-[''] before:top-0 before:left-0 before:bg-troo-primary hover:before:top-1 hover:before:left-1  before:absolute before:z-[-1] z-auto before:transition-all before:ease-in-out before:duration-300"
              key={i}
            >
              <div className="relative before:absolute before:content-[''] before:w-full before:h-0 before:bg-troo-secondary before:opacity-50 before:bottom-0 before:transition-all before:ease-in-out before:duration-500 group-hover:before:h-full">
                <img className="w-full" src={e.img} alt={e.name} />
              </div>
              <div className="absolute w-[80%] bottom-5 bg-troo-white flex flex-col justify-center items-center gap-2 p-4 group-hover:bg-troo-secondary  transition-all ease-in-out duration-300 left-1/2 transform -translate-x-1/2">
                <div className="flex gap-2 relative max-h-0 opacity-0 mt-0 scale-y-0 group-hover:max-h-[400px] group-hover:opacity-100 group-hover:mt-[-40px] group-hover:bg-troo-primary group-hover:py-3 group-hover:px-2 group-hover:scale-y-100 transition-all ease-in-out duration-300 origin-bottom mx-auto">
                  {e.social.map((ele, ind) => (
                    <div className="rounded-full bg-troo-white p-2 " key={ind}>
                      {ele.icon}
                    </div>
                  ))}
                </div>
                <h3 className="group-hover:text-troo-white">{e.name}</h3>
                <p className="group-hover:text-troo-white">{e.sub}</p>
              </div>
            </Link>
          ))}
        </motion.div>
        {isHomePage && (
          <div className="text-center mt-10">
            <Link className="btn1 bg-troo-primary hover:bg-troo-secondary text-troo-white group transition-all ease-in-out duration-500">
              View Full List Of Member
              <HiOutlineArrowSmRight className="w-8 h-8 p-1 flex justify-center items-center bg-troo-secondary group-hover:bg-troo-primary transition-all ease-in-out duration-500  rounded-full" />
            </Link>
          </div>
        )}
        {isTeamPage && (
          <div className="flex flex-col gap-5 items-center mt-10">
            <h3>Want Join Our Team?</h3>
            <Link className="btn1 bg-troo-primary hover:bg-troo-secondary text-troo-white group transition-all ease-in-out duration-500">
              Join Us
              <HiOutlineArrowSmRight className="w-8 h-8 p-1 flex justify-center items-center bg-troo-secondary group-hover:bg-troo-primary transition-all ease-in-out duration-500  rounded-full" />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default Teams;
