import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import ProjectDetails from '../../Components/ProjectDetails'
import Highlight3 from '../../Components/Highlight3'
import useDocumentTitle from '../../Hooks/PageTitle'

const ProjectDetailsPage = () => {
  useDocumentTitle("TRoo Web Design Agency | Project Details Page")
  return (
   <Layout>
    <BannerGlobal/>
    <ProjectDetails/>
    <Highlight3/>
   </Layout>
  )
}

export default ProjectDetailsPage