import React from "react";
import { HiOutlineArrowSmRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import { motion } from "framer-motion";

const Highlight3 = () => {
  return (
    <div className="bg-highlight3-bg bg-no-repeat w-full bg-cover py-12 lg:py-32">
      <div className="main_container1">
        <div className="flex flex-col gap-12 justify-center items-center">
          <motion.h2
            initial={{ y: -100 }}
            whileInView={{ y: 1 }}
            transition={{ duration: 1, type: "spring", stiffness: 100 }}
            className="max-w-[650px] text-center text-troo-white"
          >
            Solve all your web design problems under one room
          </motion.h2>
          <div className="flex flex-col md:flex-row  gap-8">
            <Link className="btn1 bg-troo-primary hover:bg-troo-white text-troo-white hover:text-troo-secondary group transition-all ease-in-out duration-500">
              Contact Us Now
              <HiOutlineArrowSmRight className="w-8 h-8 p-1 flex justify-center items-center bg-troo-secondary group-hover:bg-troo-primary transition-all ease-in-out duration-500  rounded-full" />
            </Link>
            <Link className="btn1 bg-troo-primary hover:bg-troo-white text-troo-white hover:text-troo-secondary group transition-all ease-in-out duration-500">
              Contact Us Now
              <HiOutlineArrowSmRight className="w-8 h-8 p-1 flex justify-center items-center bg-troo-secondary group-hover:bg-troo-primary transition-all ease-in-out duration-500  rounded-full" />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Highlight3;
