import feedback1 from "../images/feedbackImg1.png"
import feedback2 from "../images/feedbackImg2.png"
import feedback3 from "../images/feedbackImg3.png"
import feedback4 from "../images/feedbackImg4.png"
import feedback5 from "../images/feedbackImg5.png"
import feedback6 from "../images/feedbackImg6.png"
import feedback7 from "../images/feedbackImg7.png"
import feedback8 from "../images/feedbackImg8.png"
import feedback9 from "../images/feedbackImg9.png"
import feedback10 from "../images/feedbackImg10.png"


export const FeedBackData = [
    {
        id : 1,
        img : feedback1,
        name : "Freddy Lee",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have variations of passages of Lorem Ipsum suffered alteration in some variations of passages form.",
        sub : "Marketing Head"
    },
    {
        id : 2,
        img : feedback2,
        name : "Emma Watson",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have variations of passages of Lorem Ipsum suffered alteration in some variations of passages form.",
        sub : "Product Designer"
    },
    {
        id : 3,
        img : feedback3,
        name : "Renny Young",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have variations of passages of Lorem Ipsum suffered alteration in some variations of passages form.",
        sub : "Accountant"
    },
    {
        id : 4,
        img : feedback4,
        name : "Jonathan B. Bohnert",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have variations of passages of Lorem Ipsum suffered alteration in some variations of passages form.",
        sub : "Business Consultant"
    },
    {
        id : 5,
        img : feedback5,
        name : "Ramon Gottlieb",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have variations of passages of Lorem Ipsum suffered alteration in some variations of passages form.",
        sub : "Accountant"
    },
    {
        id : 6,
        img : feedback6,
        name : "Ruth Mohr",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have variations of passages of Lorem Ipsum suffered alteration in some variations of passages form.",
        sub : "Marketing Head"
    },
    {
        id : 7,
        img : feedback7,
        name : "Rosie Wilkinson",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have variations of passages of Lorem Ipsum suffered alteration in some variations of passages form.",
        sub : "Product Designer"
    },
    {
        id : 8,
        img : feedback8,
        name : "Alvis Hermiston",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have variations of passages of Lorem Ipsum suffered alteration in some variations of passages form.",
        sub : "Business Consultant"
    },
    {
        id : 9,
        img : feedback9,
        name : "Samir Corkery",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have variations of passages of Lorem Ipsum suffered alteration in some variations of passages form.",
        sub : "Marketing Head"
    },
    {
        id : 10,
        img : feedback10,
        name : "Helena Bernhard",
        para : "There are many variations of passages of Lorem Ipsum available, but the majority have variations of passages of Lorem Ipsum suffered alteration in some variations of passages form.",
        sub : "Business Consultant"
    },
]