export const CarrierData = [
  {
    id: 1,
    openingNum: "05",
    name: "UX/UI Designer",
    experiance: "3.5 Year Experience",
    job_type: "Full Time",
    location: "Surat, India",
    vacancy: "5 open",
    date: "22, December, 2022",
  },
  {
    id: 2,
    openingNum: "10",
    name: "Web Designer",
    experiance: "2.5 Year Experience",
    job_type: "Full Time",
    location: "San Francisco, CA",
    vacancy: "02 open",
    date: "28, December, 2022",
  },
  {
    id: 3,
    openingNum: "10",
    name: "Mobile App",
    experiance: "5 Year Experience",
    job_type: "Full Time",
    location: "NewYork, USA",
    vacancy: "10 open",
    date: "29, December, 2022",
  },
  {
    id: 4,
    openingNum: "04",
    name: "Web Developer",
    experiance: "2.5 Year Experience",
    job_type: "Full Time",
    location: " London, UK",
    vacancy: "4 open",
    date: "21, December, 2022",
  },
  {
    id: 5,
    openingNum: "01",
    name: "Technical Sopport",
    experiance: "5 Year Experience",
    job_type: "Full Time",
    location: "Bhavnagar, India",
    vacancy: "01 open",
    date: "20, December, 2022",
  },
  {
    id: 6,
    openingNum: "03",
    name: "Content Writer",
    experiance: "3.5 Year Experience",
    job_type: "Full Time",
    location: "Dubai, UAE",
    vacancy: "3 open",
    date: "15, December, 2022",
  },
];
