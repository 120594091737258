import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import CarrierDetails from '../../Components/CarrierDetails'
import useDocumentTitle from '../../Hooks/PageTitle'

const CarrierDetailsPage = () => {
  useDocumentTitle("TRoo Web Design Agency | Carrier Details Page")
  return (
   <Layout>
    <BannerGlobal/>
    <CarrierDetails/>
   </Layout>
  )
}

export default CarrierDetailsPage