import React from 'react'
import Layout from '../../Components/Layout'
import BannerGlobal from '../../Components/BannerGlobal'
import Blogs from '../../Components/Blogs'
import useDocumentTitle from '../../Hooks/PageTitle'

const BlogsPage = () => {
  useDocumentTitle("TRoo Web Design Agency | Blogs Page")
  return (
    <Layout>
        <BannerGlobal/>
        <Blogs/>
    </Layout>
  )
}

export default BlogsPage