import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Routes/Home";
import AboutPage from "./Routes/AboutPage";
import ServicePage from "./Routes/Service/ServicePage";
import ServiceDetailsPage from "./Routes/Service/ServiceDetailsPage";
import ProjectPage from "./Routes/Pages/ProjectPage";
import ProjectDetailsPage from "./Routes/Pages/ProjectDetailsPage";
import TeamsPage from "./Routes/Pages/TeamsPage";
import TeamDetailsPage from "./Routes/Pages/TeamDetailsPage";
import TestimonialPage from "./Routes/Pages/TestimonialPage";
import PricingPage from "./Routes/Pages/PricingPage";
import FAQsPage from "./Routes/Pages/FAQsPage";
import Error404Page from "./Routes/Pages/Error404Page";
import ComingSoonPage from "./Routes/Pages/ComingSoonPage";
import BlogsPage from "./Routes/Blogs/BlogsPage";
import BlogDetailsPage from "./Routes/Blogs/BlogDetailsPage";
import ContactsPage from "./Routes/ContactsPage";
import CarrierPage from "./Routes/Carrier/CarrierPage";
import CarrierDetailsPage from "./Routes/Carrier/CarrierDetailsPage";

function App() {
  return (
    <div className="App">
      <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/about-us" element={<AboutPage/>} />
        <Route path="/our-services" element={<ServicePage/>} />
        <Route path="/our-services/services-details" element={<ServiceDetailsPage/>} />
        <Route path="/our-projects" element={<ProjectPage/>} />
        <Route path="/our-projects/projects-details" element={<ProjectDetailsPage/>} />
        <Route path="/our-team" element={<TeamsPage/>} />
        <Route path="/our-team/our-team-details" element={<TeamDetailsPage/>} />
        <Route path="/testimonial" element={<TestimonialPage/>} />
        <Route path="/pricing-plan" element={<PricingPage/>} />
        <Route path="/faq" element={<FAQsPage/>} />
        <Route path="/error-404" element={<Error404Page/>} />
        <Route path="/coming-soon" element={<ComingSoonPage/>} />
        <Route path="/our-blogs" element={<BlogsPage/>} />
        <Route path="/our-blogs/blog-details" element={<BlogDetailsPage/>} />
        <Route path="/contact-us" element={<ContactsPage/>} />
        <Route path="/get-a-quote" element={<ContactsPage/>} />
        <Route path="/carrier" element={<CarrierPage/>} />
        <Route path="/carrier/job-details" element={<CarrierDetailsPage/>} />
        
        
      </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
