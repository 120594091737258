import React, { useState } from "react";
import { BsCheck2 } from "react-icons/bs";
import { HiOutlineArrowSmRight } from "react-icons/hi";
import { Link } from "react-router-dom";
import highlightImg1 from "../images/highlightsImg.png";
import { motion } from "framer-motion";
import CountUp from "react-countup";
import ScrollTrigger from "react-scroll-trigger"

const Highlights = () => {
  const [counter, setCounter] = useState(false);
  return (
    <div className="py-12 lg:py-24 bg-troo-white">
      <div className="main_container1">
        <div className="flex flex-col lg:flex-row  gap-10 justify-between">
          <motion.div
            initial={{ x: -100 }}
            whileInView={{ x: 1 }}
            transition={{ duration: 1, type: "spring", stiffness: 100 }}
            className="flex flex-col  gap-5"
          >
            <h2 className="lg:max-w-[450px]">
              We unlock the potential of your business with creative design
            </h2>
            <p className="lg:max-w-[450px]">
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered alteration in some form, by
              injected humour.
            </p>
            <div className="flex flex-col gap-3 py-3">
              <p className="flex items-center gap-2">
                <BsCheck2 className="text-troo-white bg-troo-primary rounded-full p-[2px] " />
                Easy to Use, Built Technology
              </p>
              <p className="flex items-center gap-2">
                <BsCheck2 className="text-troo-white bg-troo-primary rounded-full p-[2px] " />
                Easy to Built onPowerful Technology
              </p>
              <p className="flex items-center gap-2">
                <BsCheck2 className="text-troo-white bg-troo-primary rounded-full p-[2px] " />
                Easy to Use, Built onPowerful
              </p>
              <p className="flex items-center gap-2">
                <BsCheck2 className="text-troo-white bg-troo-primary rounded-full p-[2px] " />
                Easy to Use, Built Technology
              </p>
            </div>
            <div>
              <Link className="btn1 bg-troo-primary hover:bg-troo-secondary text-troo-white group transition-all ease-in-out duration-500">
                Contact Us Now
                <HiOutlineArrowSmRight className="w-8 h-8 p-1 flex justify-center items-center bg-troo-secondary group-hover:bg-troo-primary transition-all ease-in-out duration-500  rounded-full" />
              </Link>
            </div>
          </motion.div>
          <motion.div
            initial={{ x: 100 }}
            whileInView={{ x: 1 }}
            transition={{ duration: 1, type: "spring", stiffness: 100 }}
            className="flex flex-col md:flex-row gap-8 justify-center relative z-[1]"
          >
            <div className="relative  before:content-[''] before:absolute before:w-full before:h-full before:bg-troo-primary before:rounded before:top-2 before:right-2 z-auto before:z-[-1]">
              <img
                className=" w-full h-full rounded"
                src={highlightImg1}
                alt="highlightimg1"
              />
              <div
                className="absolute lg:left-[-80px] left-[50%] -translate-x-1/2 lg:-translate-x-0 bottom-6 gap-2 rounded-full   flex flex-col items-center justify-center  w-44 h-44 border-4 border-troo-primary bg-troo-white text-center"
              >
                <h2>15+</h2>
                <h4 className="">Years of experiences</h4>
              </div>
            </div>
            <div className=" flex flex-col rounded ">
              <div className=" text-center text-troo-white px-10 py-7 bg-troo-primary hover:bg-opacity-90 transition-all ease-in-out duration-500">
                <h2><ScrollTrigger
                  onEnter={() => setCounter(true)}
                  onExit={() => setCounter(false)}
                >
                  {counter && (
                    <CountUp start={0} end={10} duration={3} delay={0} />
                  )}
                  <strong>k+</strong>
                </ScrollTrigger></h2>
                <h4>Project completed</h4>
              </div>
              <div className=" text-center text-troo-white px-10 py-7 bg-troo-primary hover:bg-opacity-90 transition-all ease-in-out duration-500">
                <h2><ScrollTrigger
                  onEnter={() => setCounter(true)}
                  onExit={() => setCounter(false)}
                >
                  {counter && (
                    <CountUp start={0} end={8} duration={3} delay={0} />
                  )}
                  <strong>k+</strong>
                </ScrollTrigger></h2>
                <h4>Happy customers</h4>
              </div>
              <div className=" text-center text-troo-white px-10 py-7 bg-troo-primary hover:bg-opacity-90 transition-all ease-in-out duration-500">
                <h2><ScrollTrigger
                  onEnter={() => setCounter(true)}
                  onExit={() => setCounter(false)}
                >
                  {counter && (
                    <CountUp start={0} end={22} duration={3} delay={0} />
                  )}
                  <strong>k+</strong>
                </ScrollTrigger></h2>
                <h4>World Wide Branch</h4>
              </div>
              <div className=" text-center text-troo-white px-10 py-7 bg-troo-primary hover:bg-opacity-90 transition-all ease-in-out duration-500">
                <h2><ScrollTrigger
                  onEnter={() => setCounter(true)}
                  onExit={() => setCounter(false)}
                >
                  {counter && (
                    <CountUp start={0} end={45} duration={3} delay={0} />
                  )}
                  <strong>k+</strong>
                </ScrollTrigger></h2>
                <h4>Awardsachievement</h4>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Highlights;
