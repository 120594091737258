import React, { useState } from "react";

const Accordian = ({ title, desc, bgColor }) => {
  const [isActive, setIsActive] = useState(false);

  const handleAccordionClick = (e) => {
    setIsActive(e.target.checked && !isActive);
  };
  return (
    <div
      className={`collapse collapse-plus bg-troo-lightpink border-l border-troo-gad1 cursor-pointer rounded-sm ${
        isActive
          ? "bg-troo-white text-troo-deepblack collapse-open relative  before:content-[''] before:absolute before:w-full before:h-full before:bg-troo-primary before:rounded before:top-2 before:left-2 z-auto before:z-[-1] overflow-visible"
          : `${
              bgColor || "text-troo-secondary"
            } text-troo-stateTertiary collapse-close`
      }`}
    >
      <input type="radio" name="my-accordion-1" onClick={handleAccordionClick} />
      <div
        className={`collapse-title text-troo-deepblack font-Montserrat font-bold px-3 md:px-4
         ${isActive ? " text-troo-primary active mb-3 " : ""}`}
      >
        <h4 className={`pr-8 ${isActive ? "pb-3 border-b border-x-troo-fadblue" : ""}`}>{title}</h4>
      </div>
      <div className="collapse-content px-4 flex gap-3">
        <p className="pl-5 border-l border-troo-primary">{desc}</p>
      </div>
    </div>
  );
};

export default Accordian;
