import aboutimg1 from "../images/about1.svg"
import aboutimg2 from "../images/about2.svg"
import aboutimg3 from "../images/about3.svg"
import aboutimg4 from "../images/about4.svg"

export const AboutData = [
    {
        id :1,
        name : "Accountability",
        img : aboutimg1,
        para : "There are many variations of passages of Lorem Ipsum available, but the majority."
    },
    {
        id :2,
        name : "Quality",
        img : aboutimg2,
        para : "There are many variations of passages of Lorem Ipsum available, but the majority."
    },
    {
        id :3,
        name : "Proactivity",
        img : aboutimg3,
        para : "There are many variations of passages of Lorem Ipsum available, but the majority."
    },
    {
        id :4,
        name : "Agility",
        img : aboutimg4,
        para : "There are many variations of passages of Lorem Ipsum available, but the majority."
    },
]