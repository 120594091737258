export const FaqData = [
  {
    id: 1,
    name: "Will you maintain my site for me?",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since dummy text of the printing and typesetting industry. Lorem Ipsum.",
  },
  {
    id: 2,
    name: "How long does it take to build a website?",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since dummy text of the printing and typesetting industry. Lorem Ipsum.",
  },
  {
    id: 3,
    name: "What if I need help on my site down the road?",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since dummy text of the printing and typesetting industry. Lorem Ipsum.",
  },
  {
    id: 4,
    name: "Can I update the website myself once it’s built?",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since dummy text of the printing and typesetting industry. Lorem Ipsum.",
  },
  {
    id: 5,
    name: "I want to have an email signup form on my website? How can I do this?",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since dummy text of the printing and typesetting industry. Lorem Ipsum.",
  },
  {
    id: 6,
    name: "How many pages do I get with my website?",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since dummy text of the printing and typesetting industry. Lorem Ipsum.",
  },
  {
    id: 7,
    name: "How do you build my website?",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since dummy text of the printing and typesetting industry. Lorem Ipsum.",
  },
  {
    id: 8,
    name: "I already have a website, how easy is it to change it?",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since dummy text of the printing and typesetting industry. Lorem Ipsum.",
  },
  {
    id: 9,
    name: "How long will it take to get to the 1st page of Google?",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since dummy text of the printing and typesetting industry. Lorem Ipsum.",
  },
  {
    id: 10,
    name: "What platform do you build your websites on?",
    para: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since dummy text of the printing and typesetting industry. Lorem Ipsum.",
  },
];
