import brand1 from "../images/b_logo1.png";
import brand2 from "../images/b_logo2.png";
import brand3 from "../images/b_logo3.png";
import brand4 from "../images/b_logo4.png";
import brand5 from "../images/b_logo5.png";
import brand6 from "../images/b_logo6.png";

export const BrandData = [
  {
    id: 1,
    img: brand1,
  },
  {
    id: 2,
    img: brand2,
  },
  {
    id: 3,
    img: brand3,
  },
  {
    id: 4,
    img: brand4,
  },
  {
    id: 5,
    img: brand5,
  },
  {
    id: 6,
    img: brand6,
  },
];
